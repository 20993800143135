import { theme } from 'styles';

export const bookingsCartContainer = (headerColor) => ({
  marginBottom: 40,

  '.titles-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '.title-styles-container': {
      color: theme.gray800,
      fontWeight: 500,
      fontSize: 16,
    },
  },

  '.not-have-bookings': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    border: `0.5px solid ${theme.gray150}`,
    boxShadow: theme.boxShadowDark,
    backgroundColor: theme.white,
    padding: '20px 24px',
    position: 'relative',

    '.empty-icon': {
      width: 326,
      height: 229,
    },

    '.title': {
      fontWeight: 500,
      color: theme.gray800,
    },
  },

  '.action-button': {
    color: theme.blue,
    fontWeight: 500,
    padding: 0,
  },

  '.bookings-container': {
    borderRadius: 10,
    border: `0.5px solid ${theme.gray150}`,
    boxShadow: theme.boxShadowDark,
    backgroundColor: theme.white,
    padding: '20px 24px',
    position: 'relative',

    '.header-color': {
      height: 6,
      backgroundColor: theme[headerColor],
      width: '90%',
      borderRadius: '0px 0px 13px 13px ',
      position: 'absolute',
      top: 0,
    },

    '.bookings': {
      padding: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '.booking-info': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.image': {
          width: 59,
          height: 57,
          borderRadius: 11,
          marginRight: 16,
        },

        '.hotel': {
          display: 'flex',
          flexDirection: 'column',

          '.name': {
            fontSize: 13,
            color: theme.gray800,
            marginBottom: 4,
          },

          '.location': {
            color: theme.gray400,
            fontWeight: 500,
            fontSize: 13,
          },
        },
      },

      '.status-price': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',

        '.price': {
          fontSize: 13,
          color: theme.gray800,
          marginBottom: 8,
        },
      },

      '&:hover': {
        backgroundColor: theme.gray100,
      },
    },
  },
});
