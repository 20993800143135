export const SUBSCRIPTION_TYPE = {
  CATALOGUE: 'catalogue',
  TYPE: 'type',
};

export const SUBSCRIPTION_TYPES = {
  BASIC: 'Basic',
  PREMIUM: 'Premium',
  GOLDEN_UNLIMITED: 'GoldenUnlimited',
};
