import { theme } from 'styles';

export const amenitiesModalContainer = {
  '.scrollable-container': {
    height: 300,
    overflow: 'auto',

    '.amenities-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px 0',

      '.icon-check': {
        marginRight: 8,
        color: theme.successDark,
      },
    },
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
  },
};
