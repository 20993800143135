import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Table, TransactionsFilterBar } from 'components';
import { TRANSACTIONS_SORTING_OPTIONS } from 'enums';
import { getAllTransactions } from 'services';
import { allTransactionsColumns } from './config';
import { transactionsContainer } from './styles';

const filterKeys = {
  status: 'status',
  kinds: 'kinds',
  subKind: 'subKind',
  reason: 'reason',
  amountFrom: 'amountFrom',
  amountTo: 'amountTo',
  createdDateFrom: 'createdDateFrom',
  createdDateTo: 'createdDateTo',
  transactionId: 'transactionId',
  externalTransactionId: 'externalTransactionId',
  senderEmail: 'senderEmail',
  receiverEmail: 'receiverEmail',
  pageIndex: 'pageIndex',
  pageSize: 'pageSize',
  sortByDate: 'sortByDate',
  userId: 'userId',
};

const TransactionsPage = () => {
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  return (
    <div css={transactionsContainer}>
      <h3 className="title">{getText('transactions')}</h3>
      <Table
        hasPagination
        getDataKey="results"
        emptyMessage={getText('thereAreNoTransactions')}
        getDataMethod={getAllTransactions}
        columns={allTransactionsColumns(getText, selectedCurrency)}
        filterBar={TransactionsFilterBar}
        filterKeys={filterKeys}
        defaultQueryParams={{ [filterKeys.sortByDate]: TRANSACTIONS_SORTING_OPTIONS.DESC }}
      />
    </div>
  );
};

export default TransactionsPage;
