import { useState, cloneElement, isValidElement } from 'react';
import { isFunction, isArray, get, set } from 'lodash-es';
import { copy } from '@veraio/core';
import { useIsMounted, useDeepEffect } from '../hooks';
import { getFormInputs, requiredFieldValidation } from './utils';

const childArr = (childs) => (isArray(childs) ? childs : [childs]);

export const useForm = ({ values: initValues = {}, children: initChildren, onSubmit, onChange, onReset, disabled }) => {
  // Every input has it's own state but all values that are typed by the user need to be saved into state attached into the form
  const children = childArr(initChildren);
  const [values, setValues] = useState(copy(initValues));
  const [loading, setLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const isMounted = useIsMounted();

  const formOptions = {
    onSubmit: () => handleSubmit(),
    resetForm: () => resetForm(),
    onChange: (value, formId) => handleValuesChange(value, formId),
    values,
    setValues,
    loading,
    setLoading,
    isTouched,
    setIsTouched,
  };

  useDeepEffect(() => {
    setValues(copy(initValues));
  }, [initValues]);

  // All form values are stored to inputs ref, every input field has a state
  const handleValuesChange = (val, formId) => {
    setValues((prev) => {
      const newValues = set({ ...prev }, formId, val);
      // We need this sto because we can have a state change coming from onChange of the form (if change one field will lead to clear of other filed for example). In this case the two setStates are receiving the old state, so we need first to set the state then to call onChange, which can call another setState with already updated values
      setTimeout(() => {
        isFunction(onChange) && onChange(formId, { ...formOptions, values: newValues });
      });
      return newValues;
    });
  };

  const handleSubmit = async () => {
    !isTouched && setIsTouched(true);

    const submitErrors = [];
    getFormInputs(children.map(cloneChildren)).forEach((el) => {
      const inputValue = get(values, el?.props?.formId);
      if (isFunction(el?.props?.validate)) submitErrors.push(el.props.validate(inputValue, values));
    });

    if (submitErrors.filter(Boolean).length || !isFunction(onSubmit)) {
      // eslint-disable-next-line no-console
      console.log(submitErrors);
      return;
    }

    setLoading(true);
    const error = await onSubmit(values);

    isMounted.current && (error ? setLoading(false) : resetForm());
  };

  const resetForm = () => {
    setValues({});
    setIsTouched(false);
    setLoading(false);
    isFunction(onReset) && onReset(formOptions);
  };

  const cloneChildren = (allChilds) => {
    const getChilds = (childs) => childArr(childs).filter(Boolean);

    const generateChilds = (child, i) => {
      const { props = {} } = child;
      const { children: childs, formId } = props;
      const hasId = props && formId;
      const key = formId ?? `Child${i}`;

      const childFormProps = (childProps) => {
        const isTouchedProp = isTouched || childProps.isTouched;
        const disabledProp = disabled || childProps.disabled;

        return {
          onChange: (val) => handleValuesChange(val, childProps.formId),
          value: get(values, childProps.formId),
          ...((isFunction(childProps.validate) || props?.required) && {
            validate: (val) =>
              isFunction(childProps.validate) ? childProps.validate(val, values) : requiredFieldValidation(val, child),
          }),
          ...(isTouchedProp && { isTouched: isTouchedProp }),
          ...(disabledProp && { disabled: disabledProp }),
        };
      };

      switch (true) {
        case isValidElement(child):
          return cloneElement(
            child,
            hasId
              ? { key, ...childFormProps(props) }
              : childs
              ? { key, children: getChilds(childs).map(cloneChildren) }
              : child,
          );
        case isFunction(child):
          return cloneChildren(child(formOptions));
        default:
          return child;
      }
    };

    return getChilds(allChilds).map(generateChilds);
  };

  return {
    handleSubmit,
    loading,
    reset: resetForm,
    values,
    newChildren: children.map(cloneChildren),
  };
};
