import { theme } from 'styles';

export const removeModalContainer = {
  '.close-icon': {
    padding: 4,
    backgroundColor: theme.gray100,
    borderRadius: '50%',
  },

  '.remove-payment-method-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '32px 8px',

    '.icon': {
      marginBottom: 16,
    },
  },

  '.action-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '.button': {
      fontWeight: 600,
    },
  },
};
