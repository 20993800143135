import { useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { Tabs, Tab } from 'components';
import { ROUTES } from 'enums';
import { initFeaturesPaymentMethods } from 'stores';
import Features from './Features';
import PaymentMethods from './PaymentMethods';
import { settingsContainer } from './styles';

const Settings = () => {
  const { getText } = useTranslations();

  useEffect(() => {
    initFeaturesPaymentMethods({ pageIndex: 1, pageSize: 100 });
  }, []);

  return (
    <div css={settingsContainer}>
      <h2 className="title">{getText('configuration')}</h2>
      <Tabs className="tabs-container" startingRoute={ROUTES.Settings}>
        <Tab url={ROUTES.PaymentMethods} label={getText('paymentMethods')} component={() => <PaymentMethods />} />
        <Tab url={ROUTES.Features} label={getText('features')} component={() => <Features />} />
      </Tabs>
    </div>
  );
};

export default Settings;
