import { useRef } from 'react';
import { isFunction } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, AddEditPaymentMethodModal, RemovePaymentMethodModal } from 'components';
import { paymentMethodsCartContainer, label } from './styles';

const PaymentMethodCart = ({
  item,
  feature,
  disabled,
  selectedPaymentMethod,
  onUpdatePaymentMethod,
  onRemove,
  onSelect,
  className,
}) => {
  const { getText } = useTranslations();
  const modalRef = useRef();
  const removeModalRef = useRef();

  const handleUpdate = (val) => {
    onUpdatePaymentMethod(item?.name, val);
    setTimeout(() => {
      modalRef.current.close();
    }, 1000);
  };

  return (
    <>
      <div
        css={paymentMethodsCartContainer(
          isFunction(onRemove),
          selectedPaymentMethod?.find((el) => el.name === item.name),
          disabled,
        )}
        {...(className && { className })}
        role="button"
        tabIndex={0}
        onClick={() => !disabled && isFunction(onSelect) && onSelect(item)}>
        <h4 className="title">{getText('paymentMethodTitle')}</h4>
        <p className="name">{item?.name}</p>

        <div className="info-container">
          <div className="row-info-text">
            <p className="percent">{item?.cashPercent}%</p>
            <p>{getText('fiat')} </p>
          </div>
          <div className="row-info-text">
            <p className="percent">{item?.coinPercent}%</p>
            <p>{getText('crypto')}</p>
          </div>
        </div>
        <div className="info-container">
          <div className="row-info">
            <Icon
              iconName={item?.isVisible ? 'done' : 'close'}
              color={item?.isVisible ? 'success' : 'error'}
              size={24}
            />
            <p css={label(item?.isVisible)}>{getText('visible')} </p>
          </div>
          <div className="row-info">
            <Icon iconName={item?.isActive ? 'done' : 'close'} color={item?.isActive ? 'success' : 'error'} size={24} />
            <p css={label(item?.isActive)}>{getText('active')}</p>
          </div>
        </div>

        {(isFunction(onUpdatePaymentMethod) || isFunction(onRemove)) && (
          <Button
            small
            type="link"
            onClick={() => (isFunction(onRemove) ? removeModalRef.current?.open() : modalRef.current?.open())}
            className="edit-button">
            {getText(isFunction(onRemove) ? 'remove' : 'edit')}
          </Button>
        )}
      </div>

      {isFunction(onRemove) && (
        <RemovePaymentMethodModal ref={removeModalRef} item={item} feature={feature} onRemove={onRemove} />
      )}

      {isFunction(onUpdatePaymentMethod) && (
        <AddEditPaymentMethodModal
          ref={modalRef}
          isEditMode
          title={getText('editPaymentMethod')}
          paymentMethod={item}
          handleSubmit={handleUpdate}
        />
      )}
    </>
  );
};

export default PaymentMethodCart;
