import { useTranslations } from '@veraio/strank';
import { Button, Form, Row, Col, Input, CheckButton } from 'components';
import { editSubscriptionCatalogueContainer } from './styles';

const positiveIntPattern = (val) => /^[0-9]$/.test(val);
const pricePattern = (val) => /^[0-9]|\.$/.test(val);

const EditSubscriptionCatalogueCart = ({ subscription, handleEdit, onUpdateSubscription }) => {
  const { getText } = useTranslations();

  return (
    <div css={editSubscriptionCatalogueContainer}>
      <p className="plan">{getText(subscription?.subscriptionTypeName)}</p>
      <Form values={subscription} onSubmit={(val) => onUpdateSubscription(subscription?.id, val)}>
        <Row gap={8} className="rows">
          <Col sm={12} md={12}>
            <Input
              required
              formId="price"
              label={getText('price')}
              pattern={pricePattern}
              validate={(value) =>
                value <= 0 && {
                  msg: getText('canNotBeLessThanOrEqualTo', { count: 0 }),
                }
              }
            />
          </Col>
          <Col sm={12} md={12}>
            <Input
              required
              formId="duration"
              label={getText('durationInMonths')}
              pattern={positiveIntPattern}
              validate={(val) =>
                val <= 0 && {
                  msg: getText('canNotBeLessThanOrEqualTo', { count: 0 }),
                }
              }
            />
          </Col>
          <Col sm={12} md={6}>
            <CheckButton formId="isVisible">{getText('visible')}</CheckButton>
          </Col>
          <Col sm={12} md={6}>
            <CheckButton formId="isActive">{getText('active')}</CheckButton>
          </Col>
          <Col sm={12} md={6}>
            <CheckButton formId="isRecommended">{getText('recommended')}</CheckButton>
          </Col>
        </Row>
        {({ onSubmit }) => (
          <div className="actions">
            <Button type="secondary" onClick={() => handleEdit(false)}>
              {getText('cancel')}
            </Button>
            <Button onClick={onSubmit}>{getText('submit')}</Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default EditSubscriptionCatalogueCart;
