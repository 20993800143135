import { theme } from 'styles';

export const tableHeaderContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: 900,
  width: '100%',

  '.input-options-dropdown': {
    marginTop: 0,
    height: 48,
    flex: 0,

    '.select-dropdown': {
      backgroundColor: theme.white,
      borderRadius: '10px 0 0 10px',
      border: `1px solid ${theme.gray150}`,
      minHeight: '4.5rem',
    },
  },

  '.search-input': {
    marginRight: 24,
    width: '100%',
    flex: 1,

    '.input': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 8,
      height: 45,
      borderRadius: '0 10px 10px 0',
    },

    '@media (max-width: 767px)': {
      marginRight: 0,
    },
  },

  '@media (max-width: 767px)': {
    margin: '0 2em 0 0',
  },

  '@media (max-width: 1199px)': {
    marginBottom: 8,
  },
};
