import { getToken, getRoles, hasAtLeastOneRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useStore, createStore, showApiError } from 'components';
import { getOneLifeProfile, getOneVoyageProfile, createUser } from 'services';

const userStore = createStore({
  isAuthenticated: !!getToken(),
  userInfo: null,
  isAuthorized: hasAtLeastOneRole([USER_ROLES.ADMINISTRATOR]),
  roles: null,
});

const createNewUser = async (lifeUser) => {
  const [createInternalUser, createInternalUserErr] = await createUser();
  if (createInternalUserErr) return showApiError(createInternalUserErr);

  userStore.setState({
    userInfo: { ...createInternalUser, ...lifeUser },
    isAuthenticated: true,
    roles: getRoles(),
    isAuthorized: hasAtLeastOneRole([USER_ROLES.ADMINISTRATOR]),
  });
};

export const initializeUser = async () => {
  const [lifeUser, lifeErr] = await getOneLifeProfile();
  if (lifeErr) return showApiError(lifeErr);

  const [voyageProfile, voyageProfileError] = await getOneVoyageProfile();
  if (voyageProfileError) {
    voyageProfileError.response.status === 400 &&
    voyageProfileError.response.data.messages?.find((el) => el === 'The user does not exist!')
      ? createNewUser(lifeUser)
      : showApiError(voyageProfileError);
  }

  userStore.setState({
    userInfo: { ...voyageProfile, ...lifeUser },
    isAuthenticated: true,
    roles: getRoles(),
    isAuthorized: hasAtLeastOneRole([USER_ROLES.ADMINISTRATOR]),
  });
};

export const useUserStore = (...args) => useStore(userStore, ...args);
