/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */
import { isArray, isEmpty, isNull, isNumber, isString } from 'lodash-es';

export const isStrNum = (val) => isString(val) || isNumber(val);

export const isJsonString = (text) => {
  if (typeof text !== 'string') return false;

  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const concatStrings = (...args) => args.filter(Boolean).join(' ');

export const uuid = () => {
  const pattern = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
  return pattern.replace(/x/g, () => ((Math.random() * 16) | 0).toString(16));
};

export const unset = (obj, key) => {
  const properties = isArray(key) ? key : key.split('.');
  const newObj = { ...obj };
  if (properties.length === 1) {
    delete newObj[properties.pop()];
    return newObj;
  }
  const curProperty = properties.shift();
  newObj[curProperty] = unset(newObj[curProperty], properties);
  if (isEmpty(newObj[curProperty])) delete newObj[curProperty];
  return newObj;
};

export const parseJson = (string, defaultValue) => {
  try {
    const parsed = JSON.parse(string);
    return parsed;
  } catch (e) {
    return defaultValue;
  }
};

export const formatShortText = (val, textLength) => {
  if (isNull(val) || val === '' || val === undefined) return '';

  const formattedText = val.replace(/(<([^>]+)>)/gi, '');
  const textLengthVal = textLength ?? 200;

  return formattedText.length > textLengthVal ? `${formattedText.substring(0, textLengthVal)}...` : formattedText;
};

export const deepEqual = (a, b) => {
  if (a === b) return true;

  if (typeof a === 'function' && b.toString) return a.toString() === b.toString();

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) return false;

    if (Array.isArray(a)) {
      if (a.length !== b.length) return false;
      for (let i = a.length - 1; i >= 0; i--) if (!deepEqual(a[i], b[i])) return false;
      return true;
    }

    if (a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) return false;
      for (const i of a.entries()) if (!b.has(i[0])) return false;
      for (const i of a.entries()) if (!deepEqual(i[1], b.get(i[0]))) return false;
      return true;
    }

    if (a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) return false;
      for (const i of a.entries()) if (!b.has(i[0])) return false;
      return true;
    }

    if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
      if (a.length !== b.length) return false;
      for (let i = a.length - 1; i >= 0; i--) if (a[i] !== b[i]) return false;
      return true;
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;

    for (let i = keys.length - 1; i >= 0; i--) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    for (let i = keys.length - 1; i >= 0; i--) {
      const key = keys[i];

      if (key === '_owner' && a.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        continue;
      }

      if (!deepEqual(a[key], b[key])) return false;
    }

    return true;
  }

  return false;
};
