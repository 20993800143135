import { theme } from 'styles';

export const policyContainer = {
  borderRadius: 10,
  border: `1px solid ${theme.gray150}`,
  boxShadow: theme.boxShadowDark,
  padding: '32px 24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginBottom: 40,

  '.policy-cart': {
    borderBottom: `1px solid ${theme.gray150}`,
    marginBottom: 32,

    '.policy-title': {
      marginBottom: 8,
    },

    '.paragraph': {
      color: theme.gray400,
      marginBottom: 24,
    },

    '.horizontal-line': {
      backgroundColor: theme.gray200,
    },

    '&:last-child': {
      borderBottom: 'none',
      marginBottom: 0,
    },
  },
};
