import { theme } from 'styles';

export const settingsContainer = {
  padding: '64px 100px 16px 100px',

  '.title': {
    marginBottom: 32,
  },

  '.tabs-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.tabs-list-container': {
      borderBottom: `1px solid ${theme.gray150}`,
    },
  },
};
