import { useState, useEffect, useImperativeHandle } from 'react';
import { isEqual, isEmpty, isNil, isFunction, get } from 'lodash-es';
import { useDeepEffect } from '../../hooks';
import inputValidation from '../inputValidation';
import { outputValue, prepareValue, areEquals, DEFAULT_DISPLAY_KEY, DEFAULT_UNIQUE_KEY, prepareOptions } from './utils';

export const useDropDown = ({
  value: initValue,
  options: initOptions = [],
  onChange,
  onBlur,
  onError,
  open = false,
  multiSelect = false,
  disabled = false,
  mapValue,
  required = false,
  validate,
  isTouched: isTouchedInit = false,
  displayKey = DEFAULT_DISPLAY_KEY,
  uniqueKey = DEFAULT_UNIQUE_KEY,
  renderSelected,
  placeholder,
  dropdownRef,
}) => {
  const options = prepareOptions(initOptions, displayKey);
  const selectedValue = prepareValue(initValue, options, multiSelect, displayKey, uniqueKey);
  const [selected, setSelected] = useState(selectedValue);
  const [isOpen, setIsOpen] = useState(open);
  const [error, setError] = useState(false);
  const [isTouched, setIsTouched] = useState(isTouchedInit);
  const isDisabled = !options?.length || disabled;
  // Those two references need to be states instead of refs because react-popper does not work with refs
  const [selectRef, setSelectRef] = useState();
  const [optionsListRef, setOptionsListRef] = useState();

  useImperativeHandle(dropdownRef, () => ({
    changeValue: (newValue) => handleSelect(newValue),
    clear: () => handleSelect(null),
    value: selected,
    changeIsTouched: setIsTouched,
  }));

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useEffect(() => {
    isTouched && checkForError(selected);
  }, [isTouched]);

  // When the options are async and we have value some id, we should have this effect to update selected with matched option
  useDeepEffect(() => {
    setSelected(selectedValue);
  }, [options]);

  useDeepEffect(() => {
    !areEquals(selected, selectedValue, displayKey, uniqueKey) && setSelected(selectedValue);
  }, [initValue, options]);

  const handleToggle = () => !isDisabled && setIsOpen((prev) => !prev);

  const handleKeyDown = (e) => {
    if (isOpen && e.keyCode === 27) return setIsOpen(false);
    // Add support for arrows selection and enter selected option
  };

  const handleBlur = ({ currentTarget, relatedTarget }) => {
    if (!currentTarget?.contains(relatedTarget) && !optionsListRef?.contains(relatedTarget) && isOpen && !isDisabled) {
      setIsOpen(false);
      !isTouched && setIsTouched(true);
      checkForError(selected);
      isFunction(onBlur) && onBlur(hookProperties);
    }
  };

  const handleSelect = (newValue) => {
    setIsTouched(true);
    checkForError(newValue);

    if (multiSelect) {
      if (isNil(newValue)) return changeValue([]);
      const selectedUnique = selected?.filter((el) => !isNil(el));
      const valueIndex = selectedUnique.findIndex((e) => areEquals(e, newValue, displayKey, uniqueKey));

      if (valueIndex === -1) return changeValue([...selectedUnique, newValue]);

      selectedUnique.splice(valueIndex, 1);
      return changeValue(selectedUnique);
    }
    setIsOpen(false);
    return changeValue(newValue);
  };

  const changeValue = (newValue) => {
    if (isEqual(newValue, selected)) return;
    setSelected(newValue);
    isFunction(onChange) && onChange(outputValue(newValue, displayKey, mapValue));
    isOpen && selectRef?.focus();
  };

  const checkForError = (newValue) => {
    let newError = null;

    // If validate function is provided check for error
    if (isFunction(validate)) newError = validate(newValue);
    else if (required) {
      newError = multiSelect
        ? isEmpty(newValue) && { msg: 'Required field' }
        : inputValidation('required')(newValue && newValue[uniqueKey]);
    }

    isFunction(onError) && onError(newError);
    isTouched && setError(newError);
  };

  const defaultPlaceholder = placeholder && placeholder;
  const renderedSelection = isFunction(renderSelected) ? renderSelected(selected) : null;
  const getOptionValue = (val) => {
    if (!val) return defaultPlaceholder;
    if (val?.render) return val.render(val) ?? defaultPlaceholder;
    return get(val, displayKey) ?? defaultPlaceholder;
  };

  const displayValue = renderedSelection ?? (multiSelect ? defaultPlaceholder : getOptionValue(selected));

  const hookProperties = {
    isOpen,
    setIsOpen,
    isTouched,
    setIsTouched,
    isDisabled,
    selected,
    options,
    error,
    handleToggle,
    handleKeyDown,
    handleBlur,
    handleSelect,
    selectRef,
    setSelectRef,
    optionsListRef,
    setOptionsListRef,
    displayValue,
    areEquals: (val) => areEquals(selected, val, displayKey, uniqueKey),
  };

  return hookProperties;
};
