import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { camelCase, debounce, isNil, omit } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Button, DateRangePicker, Dropdown, Icon, Input, useUrlParams, TransactionsFiltersModal } from 'components';
import { TRANSACTION_STATUS_TYPE } from 'enums';
import { tableHeaderContainer } from './styles';

const DISPLAY_FORMAT = 'D MMM YYYY';
const statusOptions = Object.values(TRANSACTION_STATUS_TYPE).map((el) => ({
  label: getText(camelCase(el)),
  value: el,
}));
const searchOptions = [
  'reason',
  'transactionId',
  'externalTransactionId',
  'senderEmail',
  'receiverEmail',
  'userId',
]?.map((el) => ({
  label: getText(el),
  value: el,
}));

const TransactionsFilterBar = () => {
  const { clearQueryParams, setQueryParams, queryParams } = useUrlParams();
  const { createdDateFrom, createdDateTo, status } = queryParams;
  const [inputOption, setInputOption] = useState(
    queryParams.reason
      ? 'reason'
      : queryParams.transactionId
      ? 'transactionId'
      : queryParams.externalTransactionId
      ? 'externalTransactionId'
      : queryParams.senderEmail
      ? 'senderEmail'
      : queryParams.receiverEmail
      ? 'receiverEmail'
      : queryParams?.userId
      ? 'userId'
      : 'transactionId',
  );
  const datePickerRef = useRef();
  const modalRef = useRef();

  const handleInputOptionChange = ({ value }) => setInputOption(value);

  const handleSearchChange = debounce((value) => {
    if (isNil(inputOption)) return;

    clearQueryParams(searchOptions?.filter((el) => el.value !== inputOption)?.map((el) => el.value));
    !isNil(inputOption) &&
      setQueryParams({
        ...omit(
          queryParams,
          searchOptions?.filter((el) => el.value !== inputOption)?.map((el) => el.value),
        ),
        [inputOption]:
          inputOption === 'reason' ||
          inputOption === 'senderEmail' ||
          inputOption === 'receiverEmail' ||
          inputOption === 'userId'
            ? value
            : isNaN(Number(value))
            ? ''
            : Number(value) === 0
            ? ''
            : Number(value),

        pageIndex: 1,
        pageSize: 10,
      });
  }, 800);

  const handleStatusChange = (value) => {
    setQueryParams({ ...queryParams, status: value?.map((el) => el.value), pageIndex: 1, pageSize: 10 });
  };

  const handleDateChange = (newParams) => {
    const createdDateFromData = isNil(newParams?.startDate) ? null : moment(newParams?.startDate);
    const createdDateToData = isNil(newParams?.endDate) ? null : moment(newParams?.endDate);

    setQueryParams({
      ...queryParams,
      createdDateFrom: createdDateFromData,
      createdDateTo: createdDateToData,
      pageIndex: 1,
      pageSize: 10,
    });
  };

  return (
    <>
      <div css={tableHeaderContainer}>
        <div className="inputs-container">
          <Dropdown
            noClear
            placeholder={getText('searchBy')}
            className="input-options-dropdown"
            value={inputOption ?? searchOptions[1]}
            options={searchOptions}
            onChange={handleInputOptionChange}
          />
          <Input
            small
            showClear
            type={inputOption === 'transactionId' || inputOption === 'externalTransactionId' ? 'number' : 'text'}
            placeholder={getText('search')}
            value={queryParams[inputOption] ?? ''}
            leftIcon={{ iconName: 'search' }}
            className="search-input"
            onChange={handleSearchChange}
            onKeyDown={(e) => e.keyCode === 13 && handleSearchChange(e?.nativeEvent?.target?.value)}
          />
        </div>

        <div className="right-filters-container">
          <Dropdown
            small
            multiSelect
            placeholder={getText('status')}
            className="statuses-dropdown"
            value={status ?? null}
            options={statusOptions}
            onChange={handleStatusChange}
          />

          <DateRangePicker
            ref={datePickerRef}
            startDate={createdDateFrom}
            endDate={createdDateTo}
            onChange={handleDateChange}
            displayFormat={DISPLAY_FORMAT}
            className="date-range-picker"
          />

          <div className="vertical-line" />

          <Button
            small
            type="default"
            className="default-button"
            leftIcon={<Icon iconName="filter_list" className="button-icon" size={18} />}
            onClick={() => modalRef.current?.open()}>
            {getText('moreFilters')}
          </Button>
        </div>
      </div>

      <TransactionsFiltersModal ref={modalRef} />
    </>
  );
};

TransactionsFilterBar.propTypes = {
  getData: PropTypes.func,
};

export default TransactionsFilterBar;
