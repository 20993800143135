import { theme } from 'styles';

export const profileDetailsContainer = {
  padding: '0 128px 0 100px',
  position: 'relative',

  '.header-container': {
    boxShadow: theme.boxShadow,
    borderRadius: '0px 0px 10px 10px',
    padding: '8px 32px 16px 32px',
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',

    '.back-button': {
      marginRight: '30%',
    },

    '.header-info-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '.icon-container': {
        width: 32,
        height: 32,
        marginBottom: 8,
        padding: 4,
        backgroundColor: theme.info,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
      },

      '.bolded-title': {
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 4,
        color: theme.gray800,
      },

      '.gray-text': {
        color: theme.gray400,
      },
    },
  },

  '.title': {
    marginBottom: 24,
  },

  '.user-styles': {
    fontSize: 14,
    fontWeight: 600,
    color: theme.gray300,
    textTransform: 'upperCase',
    marginBottom: 8,
  },

  '.title-styles': {
    fontSize: 18,
    fontWeight: 500,
    color: theme.gray800,
    marginBottom: 24,
  },

  '.user-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 32,

    '.user-info-container': {
      display: 'flex',
      flexDirection: 'column',

      '.blue-text': {
        fontSize: 16,
        fontWeight: 500,
        color: theme.blue,
        marginBottom: 4,
      },

      '.gray-text': {
        fontSize: 14,
        color: theme.gray400,
        marginBottom: 4,
      },
    },

    '.user-names': {
      width: 45,
      height: 45,
      backgroundColor: theme.warningLight,
      color: theme.gray800,
      textTransform: 'uppercase',
      fontSize: 18,
      fontWeight: 600,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
};
