import { isFunction } from 'lodash-es';
import { theme } from 'styles';

export const tooltipListItem = (styles) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1em',
  cursor: 'pointer',
  color: theme.gray700,
  fontSize: '1.3rem',

  '&:hover': {
    backgroundColor: theme.gray50,
  },
  ...(isFunction(styles) && styles(theme)),
});

export const tableActionsLabel = {
  width: '3rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  paddingBottom: '1rem',

  '&:hover': {
    backgroundColor: theme.gray100,
  },
};
