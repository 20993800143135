import moment from 'moment';
import { isFunction, startCase, isNil } from 'lodash-es';
import { validDate } from './moment';
import { getNestedProperty } from './nestedValuesModifications';

export const generateName = (data = {}) => {
  if (!data) return null;
  const { firstName, middleName, lastName } = data;
  return (firstName || '').concat(middleName ? ` ${middleName}` : '').concat(lastName ? ` ${lastName}` : '') || 'N/A';
};

export const displayProperty = (config, data) => {
  const value = getNestedProperty(data, config.value?.split('.'));
  const checkFlag = (flag) => config[flag] && value !== null;

  switch (true) {
    case checkFlag('bool'):
      return value ? 'Yes' : 'No';
    case checkFlag('date'):
      return validDate(value) ? moment(value).format('DD.MM.YYYY') : 'N/A';
    case checkFlag('validDate'):
      return validDate(value) ?? 'N/A';
    case checkFlag('dateTime'):
      return validDate(value) ? moment(value).format('DD.MM.YYYY, HH:mm:ss') : 'N/A';
    case checkFlag('time'):
      return validDate(value) ? moment(value).format('HH:mm:ss') : 'N/A';
    case checkFlag('startCase'):
      return startCase(value) || 'N/A';
    case checkFlag('withHashTag'):
      return isNil(value) ? 'N/A' : `# ${value}`;
    case isFunction(config.render):
      return config.render(data);
    default:
      return value ?? 'N/A';
  }
};
