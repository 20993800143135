export const editSubscriptionContainer = {
  height: 420,
  overflow: 'auto',

  '.plan': {
    fontSize: 18,
    marginBottom: 16,
  },

  '.rows': {
    marginBottom: 24,

    '.includes-column-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
    },
  },

  '.include-input': {
    marginRight: 8,

    '.input': {
      paddingTop: 32,
    },
  },

  '.includes-container': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',

    '.delete-button': {
      padding: 0,
      position: 'absolute',
      top: 6,
      right: 100,
      zIndex: 10,

      '&:hover': {
        borderColor: 'none',
        backgroundColor: 'none',
      },

      '&:active': { outline: 'none' },
    },
  },

  '.actions': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },
};
