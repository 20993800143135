import { theme } from 'styles';

export const modalStyles = {
  '.close-icon': {
    top: 32,
  },

  '.title': {
    marginBottom: 24,
    borderBottom: `1px solid ${theme.gray150}`,
    paddingBottom: 16,
    fontWeight: 500,
    fontSize: 22,
  },

  '.rows': {
    marginBottom: 24,

    '.add-icon': {
      margin: '20px',
    },

    '.euro-symbol-styles': {
      top: '2.5rem',
    },

    '.coin-input': {
      '.input': {
        paddingLeft: '4.2em',
      },

      '.one-symbol-styles': {
        top: '2.5rem',
        fontSize: 14,
      },
    },
  },

  '.column-border-bottom': {
    borderBottom: `1px solid ${theme.gray150}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '.check-container': {
    '.label': {
      color: theme.black,
      fontWeight: 500,
      marginBottom: 8,
    },

    '.description': {
      color: theme.gray300,
    },
  },

  '.actions': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > div': {
      fontWeight: 500,
      color: theme.black,
    },

    '.cancel-button': {
      marginRight: 16,
    },
  },
};
