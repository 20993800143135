import { theme } from 'styles';

export const transactionDetailsContainer = {
  padding: '0 128px 0 100px',
  position: 'relative',

  '.header-container': {
    boxShadow: theme.boxShadow,
    borderRadius: '0px 0px 10px 10px',
    padding: '8px 32px 16px 32px',
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',

    '.back-button': {
      marginRight: '30%',
    },

    '.header-info-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '.icon': {
        width: 32,
        height: 32,
        marginBottom: 8,
      },

      '.bolded-title': {
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 4,
        color: theme.gray800,
      },

      '.gray-text': {
        color: theme.gray400,
      },
    },
  },

  '.title': {
    marginBottom: 24,
  },

  '.sender-styles': {
    fontSize: 14,
    fontWeight: 600,
    color: theme.gray300,
    textTransform: 'upperCase',
    marginBottom: 8,
  },

  '.user-info': {
    marginBottom: 8,
  },

  '.user-info-user-id': {
    marginBottom: 24,
  },
};
