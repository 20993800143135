import moment from 'moment';
import PropTypes from 'prop-types';
import { isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Row, Col, InvoicePdf, PriceLabel } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { paymentCardContainer } from './styles';

const PaymentCart = (props) => {
  const { paymentInfo, invoiceInfo } = props;
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price?.pricePaid)}
        crypto={Number(price?.cryptoPrice) ?? 0}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
      />
    );

  const paymentData = [
    !isNil(paymentInfo?.pricePaid) && {
      label: getText('totalPaid'),
      value: convertedToLocalCurrencyPrice(paymentInfo),
    },
    !isNil(paymentInfo?.cryptoPrice) && {
      label: getText('cryptoPrice'),
      value: <PriceLabel crypto={Number(paymentInfo?.cryptoPrice)} ignoreEmpty />,
    },
    !isNil(paymentInfo?.paymentDate) && {
      label: getText('paymentDate'),
      value: moment(paymentInfo?.paymentDate).format('DD.MM.YYYY'),
    },
    !isNil(paymentInfo?.paymentMethod) && {
      label: getText('paymentMethod'),
      value: paymentInfo?.paymentMethod,
    },
  ];

  return (
    <div css={paymentCardContainer}>
      <Row gap={16}>
        {paymentData?.map((el, index) => (
          <Col key={index} sm={12} lg={6}>
            <p className="label-styles">{el?.label}</p>
            <p className="value-styles">{el?.value}</p>
          </Col>
        ))}
      </Row>
      <InvoicePdf invoiceInfo={invoiceInfo} />
    </div>
  );
};

PaymentCart.propTypes = {
  paymentInfo: PropTypes.object,
  invoiceInfo: PropTypes.object,
};

export default PaymentCart;
