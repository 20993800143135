export const ROUTES = {
  // private routes
  Bookings: '/bookings',
  BookingDetails: '/bookings/details/:bookingId',
  Features: '/features',
  HomePage: '/',
  PaymentMethods: '/payment-methods',
  Profiles: '/profiles',
  ProfileDetails: '/profiles/details/:userId',
  Settings: '/settings',
  Subscriptions: '/subscriptions',
  Transactions: '/transactions',
  TransactionsDetails: '/transactions/details/:transactionId',

  // public routes
  AuthorizationCallback: '/authorization-callback',
  Logout: '/logout',
  Login: '/login',
};
