import { theme } from 'styles';
import yellowImage from 'assets/images/yellow-image.png';

export const paymentMethodsCartContainer = {
  height: '100%',
  minHeight: 150,

  '.box': {
    height: '100%',
    width: '100%',
    color: theme.black,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderRadius: 10,
    boxShadow: theme.boxShadowLighter,
    padding: 24,
    cursor: 'pointer',
    position: 'relative',

    '.add-title': {
      marginBottom: 16,
    },

    '.add-button': {
      color: theme.black,
      fontWeight: 500,

      '.icon': {
        marginRight: 4,
      },
    },

    '.yellow-box': {
      backgroundImage: `url(${yellowImage})`,
      width: '50%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      borderRadius: '0 10px 10px 0',

      '.wallet-image': {
        width: 140,
        height: 140,
        position: 'absolute',
        right: 20,
        bottom: 0,
      },
    },
  },
};
