import { useRef, useState } from 'react';
import { omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, showApiError, showSuccess, AddSubscriptionCart, SubscriptionCart, Pagination } from 'components';
import { useSubscriptionTypes } from 'stores';
import { getAllSubscriptions, updateSubscription } from 'services';
import { subscriptionTypeCataloguesContainer } from './styles';

const SubscriptionCatalogues = ({ type }) => {
  const { getText } = useTranslations();
  const subscriptionTypes = useSubscriptionTypes();
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const subscriptionCartRef = useRef();
  const subscriptionWithIncludes = allSubscriptions.map((el) => ({
    ...el,
    includes: subscriptionTypes.find((item) => item.name === el.subscriptionTypeName)?.includes,
  }));

  const fetchAllSubscriptions = async (options) => {
    const [res, err] = await getAllSubscriptions(options);
    if (err) return showApiError(err);
    setAllSubscriptions(res?.results);
    return res;
  };

  const handleUpdateSubscription = async (subscriptionId, editedData) => {
    const requestData = {
      ...omit(editedData, 'subscriptionTypeName'),
      isVisible: editedData?.isVisible ?? false,
      isActive: editedData?.isActive ?? false,
      isRecommended: editedData?.isRecommended ?? false,
    };

    const [res, err] = await updateSubscription(subscriptionId, requestData);

    if (err) return showApiError(err);
    showSuccess(getText('successfullyUpdatedSubscription'));

    setAllSubscriptions((prev) => {
      return prev.map((item) =>
        item?.subscriptionTypeName === res?.subscriptionName && item.duration === res?.duration
          ? {
              ...res,
              id: subscriptionId,
              subscriptionTypeName: res.subscriptionName,
              includes: subscriptionTypes.find((el) => el.name === res?.subscriptionName)?.includes,
            }
          : { ...item, includes: subscriptionTypes.find((el) => el.name === res?.subscriptionName)?.includes },
      );
    });

    subscriptionCartRef?.current.handleEdit(false);
  };

  return (
    <div css={subscriptionTypeCataloguesContainer}>
      <Row gap={16} className="cards-row">
        <Col sm={12} md={6} lg={4} className="card-container">
          <AddSubscriptionCart subscriptionTypes={subscriptionTypes} onRefresh={fetchAllSubscriptions} />
        </Col>
        {subscriptionWithIncludes?.length &&
          subscriptionWithIncludes?.map((el, ind) => (
            <Col sm={12} md={6} lg={4} key={ind} className="card-container">
              <SubscriptionCart
                ref={subscriptionCartRef}
                subscription={el}
                onUpdateSubscription={handleUpdateSubscription}
                type={type}
              />
            </Col>
          ))}
      </Row>
      <Pagination onChange={fetchAllSubscriptions} pageSize={5} justify="center" />
    </div>
  );
};

export default SubscriptionCatalogues;
