import { isEmpty, isNumber } from 'lodash-es';
import queryString from 'query-string';

export const getAllSubscriptionsParams = (options = {}) => {
  const { pageIndex, pageSize } = options;

  return queryString.stringify(
    {
      ...((!isEmpty(pageIndex) || isNumber(pageIndex)) && { pageIndex }),
      ...((!isEmpty(pageSize) || isNumber(pageSize)) && { pageSize }),
    },
    { arrayFormat: 'index' },
  );
};

export const getAllSubscriptionModel = (response) => ({ ...response, totalResults: response?.results?.length });
