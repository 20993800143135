import { useHistory } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Col, PaymentCurrencyCart, PriceLabel, Row, Skeleton } from 'components';
import { DEFAULT_CURRENCY, TRANSACTION_SUB_KINDS } from 'enums';
import { displayProperty } from 'utils';
import piggiCashIcon from 'assets/images/piggi-cash.svg';
import { transactionInfo } from './config';
import { transactionPaymentDetailsContainer } from './styles';

const TransactionPaymentDetails = ({ transaction, fromBooking, className }) => {
  const { getText } = useTranslations();
  const history = useHistory();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
      />
    );

  return (
    <div css={transactionPaymentDetailsContainer} {...(className && { className })}>
      <PaymentCurrencyCart
        className="payment-currency-cart"
        backgroundColor="gray100"
        iconHeight={64}
        amount={
          transaction?.transactionSubKind === TRANSACTION_SUB_KINDS.OTHER ? (
            transaction?.amount
          ) : transaction?.transactionSubKind === TRANSACTION_SUB_KINDS.BOOKING_PAYMENT_WITH_CRYPTO ? (
            <PriceLabel crypto={Number(transaction?.amount)} ignoreEmpty />
          ) : (
            convertedToLocalCurrencyPrice(transaction?.amount)
          )
        }
        amountColor={'gray400'}
        title={getText('amount')}
        image={piggiCashIcon}
      />

      {transactionInfo(getText, history, fromBooking).map((el) => (
        <Row key={el.label} className="transaction-info">
          <Col md={6}>
            <p className="label">{el?.label}</p>
          </Col>
          <Col md={6}>
            {isNil(transaction) ? (
              <Skeleton width={120} height={18} />
            ) : (
              <div className="value">{displayProperty(el, transaction)}</div>
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default TransactionPaymentDetails;
