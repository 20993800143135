import { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { showApiError, showSuccess, FeatureCart } from 'components';
import { FEATURE_TYPES } from 'enums';
import { useFeaturesStore, updatePaymentMethodsFeatures } from 'stores';
import { updateFeaturesPaymentMethods } from 'services';

const Features = () => {
  const { getText } = useTranslations();
  const modalRef = useRef();
  const { allPaymentMethods, bookingPaymentMethods, paymentMethodsToAdd } = useFeaturesStore();

  const handleUpdatePaymentMethod = async (featureName, methods) => {
    const requestData = {
      featureName,
      paymentMethods: methods,
    };

    const [res, err] = await updateFeaturesPaymentMethods(requestData);
    if (err) return showApiError(err);

    updatePaymentMethodsFeatures(res.paymentMethods, allPaymentMethods);
    modalRef.current.close();
    modalRef.current.removeSelectedPaymentMethods();
    showSuccess(getText('successfullyUpdatedPaymentMethod'));
  };

  return (
    <div>
      <FeatureCart
        ref={modalRef}
        feature={FEATURE_TYPES.BOOKING}
        featureTitle={getText('hotelBookings')}
        featurePaymentMethods={bookingPaymentMethods}
        paymentMethodsToAdd={paymentMethodsToAdd.booking}
        onUpdate={handleUpdatePaymentMethod}
      />
    </div>
  );
};

export default Features;
