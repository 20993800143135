import PropTypes from 'prop-types';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { isArray } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Icon, Image, Tooltip } from 'components';
import { slideInLeftAnimation } from '../../../../styles';
import {
  tooltipContainer,
  menuItem,
  menuItemText,
  tooltipContent,
  subMenuList,
  menuSubItem,
  subMenusTreeContainer,
  menuItemRightIcon,
} from './styles';

export const MenuItem = ({ label, icon, url, iconProps, subMenus = [], isOpen }) => {
  const hasSubMenus = isArray(subMenus) && !!subMenus.length;
  const location = useLocation();
  const { getText } = useTranslations();

  const mainMenuContent = (
    <Tooltip
      noArrow
      content={<span css={menuItemText}>{getText(`${label}`)}</span>}
      placement="right"
      css={tooltipContainer}
      contentClassName={tooltipContent}
      zIndex={1500}>
      {icon.includes('/') ? (
        <Image src={icon} width={30} height={25} />
      ) : (
        <Icon material size="2.3rem" iconName={icon} {...iconProps} />
      )}
    </Tooltip>
  );

  return hasSubMenus ? (
    matchPath(location.pathname, url) && isOpen ? (
      <>
        <NavLink to={url} css={menuItem} className="active">
          {mainMenuContent}
          {isOpen && <Icon material iconName="expand_less" color="black" css={menuItemRightIcon} />}
        </NavLink>
        <section css={subMenusTreeContainer}>
          {subMenus.map((el, ind) => (
            <NavLink
              key={el.url}
              to={el.url}
              className={matchPath(location.pathname, { path: el.url, exact: false, strict: false }) ? 'active' : ''}
              css={[menuSubItem, slideInLeftAnimation({ translateStart: '-200px, 0, 0', delay: 0.2 * ind })]}>
              {el.label}
            </NavLink>
          ))}
        </section>
      </>
    ) : (
      <Tooltip
        noArrow
        content={({ setIsOpen }) => (
          <div css={subMenuList}>
            {subMenus.map((el) => (
              <NavLink key={el.url} to={el.url} css={menuSubItem} onClick={() => setIsOpen(false)}>
                {el.label}
              </NavLink>
            ))}
          </div>
        )}
        placement="right-start"
        css={tooltipContainer}
        contentClassName={tooltipContent}
        zIndex={1500}>
        <NavLink to={url} css={menuItem}>
          {mainMenuContent}
          {isOpen && <Icon material iconName="expand_more" color="black" css={menuItemRightIcon} />}
        </NavLink>
      </Tooltip>
    )
  ) : (
    <NavLink to={url} css={menuItem}>
      {mainMenuContent}
    </NavLink>
  );
};

MenuItem.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  onSelect: PropTypes.func,
  subMenus: PropTypes.array,
  isOpen: PropTypes.bool,
};
