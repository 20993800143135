import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Localization, User } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo-horizontal.svg';
import { navBarHeaderContainer, navBarLeftSideContainer, navBarRightSideContainer } from './styles';

const NavBar = () => {
  const history = useHistory();

  return (
    <header css={navBarHeaderContainer}>
      <div css={navBarLeftSideContainer}>
        <Logo
          onClick={() => {
            history.push(ROUTES.HomePage);
          }}
        />
      </div>

      <div css={navBarRightSideContainer}>
        <Localization />
        <User />
      </div>
    </header>
  );
};

NavBar.propTypes = {
  logoLink: PropTypes.string,
};

export default NavBar;
