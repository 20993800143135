import { useState, useEffect, useImperativeHandle } from 'react';
import moment from 'moment';
import { isFunction, omit } from 'lodash-es';
import validateInput from '../inputValidation';

export const useDateRangePicker = ({
  startDate: startDateProp = null,
  endDate: endDateProp = null,
  date = null,
  onChange,
  onError,
  validate,
  disabled,
  required,
  isTouched: isTouchedInit,
  componentRef,
  displayFormat,
  ...restProps
}) => {
  const startDate = startDateProp && (moment.isMoment(startDateProp) ? startDateProp : moment(startDateProp));
  const endDate = endDateProp && (moment.isMoment(endDateProp) ? endDateProp : moment(endDateProp));
  const singleDateValue = date && (moment.isMoment(date) ? date : moment(date));
  const [value, setValue] = useState({ startDate, endDate });
  const [singleDate, setSingleDate] = useState(singleDateValue);
  const [error, setError] = useState();
  const [isTouched, setIsTouched] = useState(isTouchedInit);

  useEffect(() => {
    startDate && endDate ? setValue({ startDate, endDate }) : setValue(singleDateValue);
  }, [startDate?.format(displayFormat), endDate?.format(displayFormat), date?.format(displayFormat)]);

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useEffect(() => {
    isTouched && checkForError(value);
  }, [isTouched]);

  useImperativeHandle(componentRef, () => ({
    value,
    startDate,
    endDate,
    singleDate,
    changeValue: (newValue) => handleChange({ target: { value: newValue } }),
    checkForError,
    setError: handleError,
    changeIsTouched: setIsTouched,
    clearDates,
  }));

  const handleChange = (newDates) => {
    isFunction(onChange) && !newDates?.startDate && !newDates?.endDate && setSingleDate(newDates);
    setValue(newDates);
    checkForError(newDates);
    isFunction(onChange) && !newDates?.startDate && !newDates?.endDate && onChange(newDates);
    isFunction(onChange) && newDates?.startDate && newDates?.endDate && onChange(newDates);
  };

  const clearDates = () => {
    if (!value.startDate && !value.endDate) return;
    const emptyDates = { startDate: null, endDate: null };
    setValue(emptyDates);
    isFunction(onChange) && onChange(emptyDates);
  };

  const handleBlur = () => {
    !isTouched && setIsTouched(true);
    checkForError(value);
  };

  const checkForError = (newValue) => {
    let newError = null;

    if (isFunction(validate)) newError = validate(newValue);
    else if (required) newError = validateInput('required')(newValue);

    handleError(newError);
  };

  const handleError = (newError) => {
    isTouched && setError(newError);
    isFunction(onError) && onError(newError);
  };

  return {
    // Input props
    value,
    startDate,
    endDate,
    singleDate,
    onChange: handleChange,
    onBlur: handleBlur,
    clearDates,
    disabled,
    // Form related props
    error,
    isTouched,
    restProps: omit(restProps, ['className', 'label', 'horizontal', 'inputRef', 'formId']),
  };
};
