import { theme } from 'styles';

export const paymentCardContainer = {
  borderRadius: 10,
  border: `1px solid ${theme.gray150}`,
  boxShadow: theme.boxShadowDark,
  padding: '32px 24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginBottom: 40,

  '.label-styles': {
    color: theme.gray400,
    fontWeight: 500,
    fontSize: 15,
  },

  '.value-styles': {
    fontWeight: 500,
    fontSize: 15,
  },
};
