import { theme } from 'styles';

export const transactionPaymentDetailsContainer = {
  padding: '28px 32px',
  borderRadius: 10,
  border: `0.5px solid ${theme.gray150}`,
  marginBottom: 32,

  '.payment-currency-cart': {
    marginBottom: 32,
  },

  '.transaction-info': {
    borderBottom: `0.5px solid ${theme.gray150}`,
    padding: '16px 0',

    '.label': {
      fontSize: 16,
      color: theme.gray400,
    },

    '.value': {
      fontSize: 14,
      color: theme.gray800,
    },

    '&:last-child': {
      borderBottom: 'none',
    },
  },
};

export const transactionIdContainer = (fromBooking) => ({
  cursor: fromBooking ? 'pointer' : 'auto',
});

export const providerErrorContainer = {
  '.row': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },

  '.provider-success-dot': {
    width: 15,
    height: 15,
    backgroundColor: theme.successDark,
    marginRight: 4,
    borderRadius: '50%',
  },

  '.provider-error-dot': {
    width: 15,
    height: 15,
    backgroundColor: theme.errorDark,
    marginRight: 4,
    borderRadius: '50%',
  },
};
