import { theme } from 'styles';
import picturesIcon from 'assets/images/login-background.png';

export const publicContentContainer = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',

  '.actionColumn': {
    padding: '0 128px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '.logo': {
      height: 40,
      width: 'auto',
      position: 'absolute',
      top: 20,
      left: 20,
      cursor: 'pointer',
    },

    '.formContainer': {
      maxWidth: 400,

      '.title': {
        fontWeight: 500,
      },

      '.desc': {
        fontSize: 18,
        marginBottom: 24,
      },

      '.action-container': {
        display: 'flex',

        '.login-button': {
          width: '100%',
          borderRadius: 8,
          padding: 16,
          fontWeight: 600,
          fontSize: 16,
        },
      },
    },
  },

  '.pictureContainer': {
    backgroundColor: theme.blueDark,
    position: 'relative',

    '.picture': {
      backgroundImage: `url(${picturesIcon})`,
      backgroundPosition: '0% 40%',
      position: 'absolute',
      top: 0,
      left: -40,
      width: 'calc(100% + 40px)',
      height: '100%',
    },
  },
};
