import { theme } from 'styles';

export const container = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 12,

  '.label': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.count': {
      color: theme.gray200,
    },

    '.description': {
      marginRight: 12,
    },
  },
};
