import { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { Row, Col, showApiError, showSuccess, SubscriptionCart } from 'components';
import { useSubscriptionTypes, editSubscriptionType } from 'stores';
import { updateSubscriptionType } from 'services';
import { subscriptionTypeCataloguesContainer } from './styles';

const SubscriptionTypes = ({ type }) => {
  const { getText } = useTranslations();
  const subscriptionTypes = useSubscriptionTypes();
  const subscriptionTypesRef = useRef();

  const handleUpdateSubscription = async (subscriptionTypeName, editedData) => {
    const [res, err] = await updateSubscriptionType(subscriptionTypeName, editedData);
    if (err) return showApiError(err);

    showSuccess(getText('successfullyUpdatedSubscriptionType'));
    subscriptionTypesRef.current.handleEdit(false);
    editSubscriptionType(subscriptionTypes.map((el) => (el.name !== subscriptionTypeName ? el : res)));
  };

  return (
    <Row gap={16} css={subscriptionTypeCataloguesContainer}>
      {subscriptionTypes?.length &&
        subscriptionTypes?.map((el, ind) => (
          <Col sm={12} md={6} lg={4} key={ind} className="card-container">
            <SubscriptionCart
              ref={subscriptionTypesRef}
              type={type}
              subscription={el}
              onUpdateSubscription={handleUpdateSubscription}
            />
          </Col>
        ))}
    </Row>
  );
};

export default SubscriptionTypes;
