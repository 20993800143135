import { useState } from 'react';
import { omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Col, Row, Pagination, showApiError, showSuccess } from 'components';
import { PaymentMethodCart, AddPaymentMethodCart } from 'components/screens';
import { updateAllPaymentMethods } from 'stores';
import { getAllPaymentMethods, updatePaymentMethod } from 'services';
import { paymentMethodsContainer } from './styles';

const PaymentMethods = () => {
  const { getText } = useTranslations();
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);

  const fetchAllPaymentMethods = async (options) => {
    const [res, err] = await getAllPaymentMethods(options);
    if (err) return showApiError(err);
    setAllPaymentMethods(res.results);
    return res;
  };

  const handleUpdatePaymentMethod = async (paymentMethodName, editedData) => {
    const [res, err] = await updatePaymentMethod(paymentMethodName, omit(editedData, 'name'));
    if (err) return showApiError(err);

    showSuccess(getText('successfullyUpdatedPaymentMethod'));
    setAllPaymentMethods((prev) => prev.map((elem) => (elem?.name !== res?.name ? elem : res)));
    updateAllPaymentMethods({ pageIndex: 1, pageSize: 100 });
  };

  return (
    <div css={paymentMethodsContainer}>
      <Row gap={16} className="cards-row">
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="card-container">
          <AddPaymentMethodCart onRefresh={() => fetchAllPaymentMethods({ pageIndex: 1, pageSize: 9 })} />
        </Col>

        {allPaymentMethods?.length &&
          allPaymentMethods?.map((el, ind) => (
            <Col xs={12} sm={12} md={6} lg={6} xl={6} key={ind} className="card-container">
              <PaymentMethodCart item={el} onUpdatePaymentMethod={handleUpdatePaymentMethod} />
            </Col>
          ))}
      </Row>

      <Pagination onChange={fetchAllPaymentMethods} pageSize={9} justify="center" />
    </div>
  );
};

export default PaymentMethods;
