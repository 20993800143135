import { isEmpty, isNumber } from 'lodash-es';
import queryString from 'query-string';

export const getPaymentMethodsParams = (options = {}) => {
  const { pageIndex, pageSize } = options;

  return queryString.stringify(
    {
      ...((!isEmpty(pageIndex) || isNumber(pageIndex)) && { pageIndex }),
      ...((!isEmpty(pageSize) || isNumber(pageSize)) && { pageSize }),
    },
    { arrayFormat: 'index' },
  );
};
