import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { flatten } from 'lodash-es';
import {
  getText,
  useTranslations,
  getSavedLanguage,
  getBrowserLanguage,
  initTranslationsStore,
  initLanguagesStore,
} from '@veraio/strank';
import { initCurrenciesStore } from '@oneecosystem/dealshaker-core';
import { MenuItem, SideNav, NavBar, NavigationProvider, Notifications, useDeepEffect } from 'components';
import { ROUTES } from 'enums';
import { useUserStore, initCountries } from 'stores';
import subscriptionIcon from 'assets/images/subscription-icon.svg';
import { BookingsPage, ProfilesPage, Settings, SubscriptionPage, TransactionsPage } from './private';
import { PrivateRoutes } from './private/PrivateRoutes';
import { PublicRoutes } from './public/PublicRoutes';
import { Logout, AuthorizationCallback } from './authorization';
import { Footer, NotFound, Unauthorized } from './public';

const App = () => {
  const user = useUserStore();
  const { changeLanguage, allEnvLanguages } = useTranslations();

  useDeepEffect(() => {
    allEnvLanguages?.length && changeLanguage(getSavedLanguage() ?? getBrowserLanguage());
  }, [allEnvLanguages]);

  useEffect(() => {
    initTranslationsStore();
    initCountries();
    initLanguagesStore();
    initCurrenciesStore();
  }, []);

  return (
    <NavigationProvider>
      <Switch>
        <Route exact path={ROUTES.Logout} component={Logout} />
        <Route exact path={ROUTES.AuthorizationCallback} component={AuthorizationCallback} />
      </Switch>
      <>
        <Notifications />
        {user?.isAuthenticated ? (
          user?.isAuthorized ? (
            <SideNav
              homeRoute={ROUTES.Bookings}
              routes={flatten(Object.values(PrivateRoutes).map((el) => el.internal))}
              notFoundComponent={NotFound}
              topNavigation={NavBar}>
              <MenuItem url={ROUTES.Bookings} icon="book_5" label={getText('bookings')} component={BookingsPage} />
              <MenuItem
                url={ROUTES.Transactions}
                icon="paid"
                label={getText('transactions')}
                component={TransactionsPage}
              />
              <MenuItem url={ROUTES.Profiles} icon="group" label={getText('profiles')} component={ProfilesPage} />
              <MenuItem url={ROUTES.Settings} icon="settings" label={getText('settings')} component={Settings} />
              <MenuItem
                url={ROUTES.Subscriptions}
                icon={subscriptionIcon}
                label={getText('subscriptions')}
                component={SubscriptionPage}
              />
            </SideNav>
          ) : (
            <Route path={ROUTES.HomePage} component={Unauthorized} />
          )
        ) : (
          <PublicRoutes />
        )}
      </>
      <Footer />
    </NavigationProvider>
  );
};

export default App;
