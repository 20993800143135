import { commonStyles } from '../../../styles';

export const backgroundPicture = (props) => {
  const { size, src, width, height, position, repeat } = props;

  return [
    {
      backgroundImage: `url(${src})`,
      backgroundSize: size || 'cover',
      backgroundPosition: position || 'center',
      backgroundRepeat: repeat || 'no-repeat',
      width: width || '100%',
      height: height || '100%',
    },
    ...commonStyles(props),
  ];
};

export const imageContainer = ({ size, position, className }) => [
  {
    objectFit: size || 'cover',
    objectPosition: position || 'center',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  className,
];

export const child = ({ fontSize, color }) => [
  {
    fontWeight: 'bold',
    fontSize: fontSize || 16,
  },
  color && {
    color,
  },
];
