import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { Icon } from '../Icon';
import { checkButtonContainer, switchToggle, helpContent, helpIcon, errorStyles, switchContainer } from './styles';

const SwitchButton = (props) => {
  const {
    leftLabel,
    rightLabel,
    className,
    value = false,
    onChange,
    size = 24,
    color,
    isTouched,
    onError,
    required,
  } = props;
  const [isChecked, setIsChecked] = useState(!!value);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  useEffect(() => {
    isTouched && checkForError(isChecked);
  }, [isTouched]);

  const handleChange = () =>
    setIsChecked((prev) => {
      isFunction(onChange) && onChange(!prev);
      checkForError(!prev);
      return !prev;
    });

  const checkForError = (err) => {
    if (required && !err && isFunction(onError)) {
      onError({ msg: 'Required' });
      setError(true);
    } else setError(false);
  };

  return (
    <div css={switchContainer} {...(className && { className })}>
      <div className="switch-content-container">
        <span className="label-left">{leftLabel}</span>
        <div
          role="checkbox"
          tabIndex={0}
          css={checkButtonContainer(leftLabel || rightLabel, false, props)}
          className="switch"
          onClick={handleChange}
          aria-checked={isChecked}>
          <span
            css={switchToggle(
              {
                width: size,
                smallWidth: size - size / 4,
                padding: size / 8,
              },
              color,
              isChecked,
            )}
            className="input-indicator"
          />
        </div>
        <span className="label-right">{rightLabel}</span>
      </div>
      {error && (
        <div css={helpContent}>
          <Icon material iconName="error" css={helpIcon} size={14} />
          <span css={errorStyles}>Required!</span>
        </div>
      )}
    </div>
  );
};

SwitchButton.propTypes = {
  leftLabel: PropTypes.any,
  rightLabel: PropTypes.any,
  className: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  size: PropTypes.number,
  color: PropTypes.string,
  isTouched: PropTypes.bool,
  required: PropTypes.bool,
};

export default SwitchButton;
