import { theme } from 'styles';

export const bookingsContainer = {
  padding: '64px 100px 16px 100px',

  '.title': {
    marginBottom: 32,
  },

  '.table-head': {
    '@media (max-width: 1199px)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
  },

  '.container-body': {
    '.table-row': {
      '@media (max-width: 1199px)': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    },
  },
};

export const linkButton = {
  color: theme.blue,
  fontWeight: 500,
  padding: '0.3em 0',
};

export const bookingStyles = {
  '.black-styles': {
    color: theme.gray800,
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 500,
  },

  '.gray-styles': {
    color: theme.gray400,
    fontSize: 12,
  },

  '.blue-styles': {
    color: theme.blue,
    fontSize: 14,
    marginBottom: 8,
    fontWeight: 500,
  },
};

export const statusesContainer = {
  display: 'flex',
  flexDirection: 'column',

  '& > div': {
    marginBottom: 8,
  },
};
