import { useTranslations } from '@veraio/strank';
import { Table, ProfilesFilterBar } from 'components';
import { getAllUsers } from 'services';
import { allProfilesColumns } from './config';
import { profilesContainer } from './styles';

const filterKeys = {
  userId: 'userId',
  email: 'email',
  username: 'username',
};

const ProfilesPage = () => {
  const { getText } = useTranslations();

  return (
    <div css={profilesContainer}>
      <h3 className="title">{getText('profiles')}</h3>
      <Table
        hasPagination
        getDataKey="results"
        emptyMessage={getText('thereAreNoProfiles')}
        getDataMethod={getAllUsers}
        columns={allProfilesColumns(getText)}
        filterBar={ProfilesFilterBar}
        filterKeys={filterKeys}
      />
    </div>
  );
};

export default ProfilesPage;
