import { useRef } from 'react';
import { camelCase, pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import {
  Button,
  CheckButton,
  Col,
  Icon,
  Input,
  Form,
  Modal,
  Row,
  showApiError,
  showSuccess,
  Dropdown,
} from 'components';
import { createSubscription } from 'services';
import { paymentMethodsCartContainer, modalStyles } from './styles';

const positiveIntPattern = (val) => /^[0-9]$/.test(val);
const pricePattern = (val) => /^[0-9]|\.$/.test(val);

const AddSubscriptionCart = ({ subscriptionTypes, onRefresh }) => {
  const { getText } = useTranslations();
  const modalRef = useRef();
  const allSubscriptionTypesOptions = subscriptionTypes.map((el) => ({ ...el, name: getText(camelCase(el.name)) }));

  const handleCreateSubscription = async (data) => {
    const requestData = {
      ...pick(data, 'duration', 'price'),
      subscriptionTypeName: data?.subscriptionTypeName?.name,
      isVisible: data?.isVisible ?? false,
      isActive: data?.isActive ?? false,
      isRecommended: data?.isRecommended ?? false,
    };

    const [, err] = await createSubscription(requestData);
    if (err) return showApiError(err);
    showSuccess(getText('successfullyCreatedSubscription'));
    onRefresh();
    modalRef.current?.close();
  };

  return (
    <>
      <div css={paymentMethodsCartContainer}>
        <div className="box" role="button" tabIndex={0} onClick={() => modalRef.current?.open()}>
          <Icon iconName="add_ad" size={84} color="secondaryDark" />
        </div>
      </div>
      <Modal ref={modalRef} css={modalStyles} closeIcon>
        <h3 className="title">{getText('createSubscription')}</h3>

        <Form onSubmit={(val) => handleCreateSubscription(val)}>
          <Row gap={8} className="rows">
            <Col sm={12} md={12}>
              <Dropdown
                noClear
                required
                formId="subscriptionTypeName"
                label={getText('subscriptionName')}
                className="subscription-type-dropdown"
                uniqueKey="name"
                displayKey="name"
                options={allSubscriptionTypesOptions}
              />
            </Col>
            <Col sm={12} md={6}>
              <Input
                required
                formId="price"
                label={getText('price')}
                pattern={pricePattern}
                validate={(value) =>
                  value <= 0 && {
                    msg: getText('canNotBeLessThanOrEqualTo', { count: 0 }),
                  }
                }
              />
            </Col>
            <Col sm={12} md={6}>
              <Input
                required
                formId="duration"
                label={getText('durationInMonths')}
                pattern={positiveIntPattern}
                validate={(val) =>
                  val <= 0 && {
                    msg: getText('canNotBeLessThanOrEqualTo', { count: 0 }),
                  }
                }
              />
            </Col>
            <Col sm={12} md={4}>
              <CheckButton formId="isVisible">{getText('visible')}</CheckButton>
            </Col>
            <Col sm={12} md={4}>
              <CheckButton formId="isActive">{getText('active')}</CheckButton>
            </Col>
            <Col sm={12} md={4}>
              <CheckButton formId="isRecommended">{getText('recommended')}</CheckButton>
            </Col>
          </Row>
          {({ onSubmit }) => (
            <div className="actions">
              <Button type="secondary" onClick={() => modalRef.current?.close()}>
                {getText('cancel')}
              </Button>
              <Button onClick={onSubmit}>{getText('create')}</Button>
            </div>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddSubscriptionCart;
