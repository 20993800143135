import { theme } from 'styles';

export const profilesContainer = {
  padding: '64px 100px 16px 100px',

  '.title': {
    marginBottom: 32,
  },
};

export const linkButton = {
  color: theme.blue,
  fontWeight: 500,
  padding: '0.3em 0',
};
