import { useStore, createStore, showError } from 'components';
import { getAllSubscriptionTypes } from 'services';

const subscriptionTypesStore = createStore([]);

export const initSubscriptionTypes = async () => {
  const [res, err] = await getAllSubscriptionTypes();
  if (err) showError(err);

  subscriptionTypesStore.setState(res);
};

export const editSubscriptionType = (updateData) => subscriptionTypesStore.setState(updateData);

export const useSubscriptionTypes = (...args) => useStore(subscriptionTypesStore, ...args);
