import { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckButton, Icon, useUrlParams } from 'components';
import { tableHead, tableHeadCell, tableHeadIcon } from './styles';

const TableHead = (props) => {
  const { columns, getData, hasSelection, hasFilterBar, selectRow, allSelected } = props;
  const { queryParams, setQueryParams } = useUrlParams();
  const [sort, setSort] = useState(null);

  const changeSort = (column) => () => {
    const { value } = column;
    const isDesc = sort === value;
    const newSort = isDesc ? `${value}Descending` : sort === `${value}Descending` ? null : value;
    setSort(newSort);
    getData({
      ...queryParams,
      sort: newSort && { sortField: value, sortOrder: isDesc ? 'desc' : 'asc' },
    });
    setQueryParams({ ...queryParams, sortField: value, sortOrder: isDesc ? 'desc' : 'asc' });
  };

  return (
    <div css={tableHead(hasFilterBar)} className="table-head">
      {hasSelection && (
        <div css={tableHeadCell({ flex: '0 0 40px', render: true })}>
          <CheckButton onChange={selectRow} value={allSelected} />
        </div>
      )}
      {columns.map((column) => {
        const { sortable, name, value } = column;
        return sortable ? (
          <div key={name} css={tableHeadCell(column)} role="button" tabIndex="-1" onClick={changeSort(column)}>
            {name}
            <Icon
              material
              iconName="arrow_downward"
              css={tableHeadIcon(sort === value, sort === `${value}Descending`)}
            />
          </div>
        ) : (
          <div key={name} css={tableHeadCell(column)}>
            {name}
          </div>
        );
      })}
    </div>
  );
};

TableHead.propTypes = {
  columns: PropTypes.array,
  getData: PropTypes.func,
  hasSelection: PropTypes.bool,
  hasFilterBar: PropTypes.bool,
  selectRow: PropTypes.func,
  allSelected: PropTypes.bool,
};

export default TableHead;
