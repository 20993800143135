import { commonStyles, theme } from 'styles';

export const tabs = (props, hasPadding) => {
  const { vertical } = props;

  return [
    vertical
      ? {
          display: 'flex',
          alignItems: 'flex-start',
        }
      : {
          width: '100%',
        },
    hasPadding && {
      '& > .tabs-content': {
        padding: vertical ? '0 0 0 24px' : '24px 0 0',
      },
    },
    ...commonStyles(props),
  ];
};

export const tabsListContainer = (vertical) => ({
  display: 'flex',
  flexFlow: vertical ? 'column nowrap' : 'row wrap',
  width: vertical ? 'auto' : '100%',
  backgroundColor: theme.white,
  position: 'relative',
  zIndex: 500,
});

export const tabContainer = (props) => {
  const { pills, vertical } = props;

  return [
    {
      display: 'inline-flex',
      cursor: 'pointer',
      padding: vertical ? '1.2em 2em' : '1em 2em',
      color: theme.gray300,
      transition: 'all .2s ease-in-out',
      marginBottom: vertical ? (pills ? 8 : 0) : -1,
      position: 'relative',
      borderRadius: pills ? 8 : 0,
      fontWeight: 500,

      '&:hover': {
        color: theme.gray600,
      },

      '&.active': pills
        ? {
            backgroundColor: theme.primary,
            color: theme.white,
          }
        : vertical
        ? {
            color: theme.gray800,
            borderRight: `2px solid ${theme.primary}`,
          }
        : {
            color: theme.gray800,
            '&:before': {
              transform: 'scaleX(1)',
              backgroundColor: theme.primary,
            },
          },
    },
    pills &&
      !vertical && {
        marginRight: 8,
      },
    vertical &&
      !pills && {
        borderRight: `2px solid ${theme.gray200}`,
      },
    vertical && {
      wordWrap: 'break-word',
      maxWidth: 200,
    },
    !pills &&
      !vertical && {
        '&:before': {
          position: 'absolute',
          top: 'auto',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 4,
          content: "''",
          transition: 'all 0.4s',
          backfaceVisibility: 'hidden',
          transform: 'scaleX(0)',
          borderRadius: '6px 6px 0px 0px',
        },
      },
    ...commonStyles(props),
  ];
};
