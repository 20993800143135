import { cloneElement, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { useNavigationControls } from '../NavigationContext';
import { sideNavContainer, menuListContainer } from './styles';

const MenuList = ({ sideNavChildrens, closeBreakPoint }) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth >= closeBreakPoint);
  const [breakPoint, setBreakPoint] = useState(closeBreakPoint);
  const { setupSideNavControls } = useNavigationControls();
  const previousWidthRef = useRef(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    setupSideNavControls({
      isOpen,
      close: () => setIsOpen(false),
      open: () => setIsOpen(true),
      toggle: () => setIsOpen((prev) => !prev),
      changeBreakPoint: (val) => setBreakPoint(val),
    });

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setBreakPoint(closeBreakPoint);
    setIsOpen(window.innerWidth >= closeBreakPoint);
  }, [closeBreakPoint]);

  const handleWindowResize = debounce((e) => {
    if (e.currentTarget?.innerWidth < breakPoint && previousWidthRef.current >= breakPoint) setIsOpen(false);
    else if (e.currentTarget?.innerWidth >= breakPoint && previousWidthRef.current < breakPoint) setIsOpen(true);
    previousWidthRef.current = e.currentTarget?.innerWidth;
  }, 500);

  const MenuListItems = sideNavChildrens.map((menu, i) =>
    cloneElement(menu, {
      key: `${menu.label}-${i}`,
      isOpen,
    }),
  );

  return (
    <aside css={sideNavContainer} role="navigation">
      <section className="menu-list-container" css={menuListContainer}>
        {MenuListItems}
      </section>
    </aside>
  );
};

MenuList.propTypes = {
  sideNavChildrens: PropTypes.array,
  homeRoute: PropTypes.string,
  closeBreakPoint: PropTypes.number,
};

export default MenuList;
