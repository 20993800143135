import { theme } from 'styles';

export const paymentMethodsCartContainer = (hasRemove, select, disabled) => ({
  height: '100%',
  minHeight: 150,
  backgroundColor: disabled ? theme.whiteGray : theme.gray100,
  borderRadius: 10,
  position: 'relative',
  padding: 24,
  border: select ? `1px solid ${theme.blue}` : 'none',
  cursor: disabled ? 'not-allowed' : 'pointer',
  color: disabled ? theme.gray200 : theme.black,

  '.title': {
    marginBottom: 8,
    fontSize: 16,
  },

  '.name': {
    marginBottom: 16,
  },

  '.info-container': {
    display: 'flex',
    flexDirection: 'row',

    '.row-info': {
      marginRight: 24,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: 13,
      color: theme.success,
    },

    '.row-info-text': {
      marginRight: 24,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: 13,

      '.percent': {
        marginRight: 4,
      },
    },
  },

  '.edit-button': {
    padding: 0,
    color: hasRemove ? theme.errorDark : theme.blue,
    fontSize: 16,
    fontWeight: 600,
    position: 'absolute',
    top: 12,
    right: 24,
  },
});

export const label = (isSuccess) => ({
  color: isSuccess ? theme.success : theme.error,
});
