import { useRef } from 'react';
import { add } from 'exact-math';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Image, showApiError, showSuccess, showError, AddEditPaymentMethodModal } from 'components';
import { updateAllPaymentMethods } from 'stores';
import { createPaymentMethod } from 'services';
import wallet from 'assets/images/wallet.svg';
import { paymentMethodsCartContainer } from './styles';

const AddPaymentMethodCart = ({ onRefresh }) => {
  const { getText } = useTranslations();
  const modalRef = useRef();

  const handleCreatePaymentMethod = async (data) => {
    if (add(Number(data?.cashPercent), Number(data?.coinPercent)) > 100)
      return showError(getText('cryptoPercentWithFiatPercentNotGreaterThanOneHundred'));
    if (add(Number(data?.cashPercent), Number(data?.coinPercent)) < 100)
      return showError(getText('cryptoPercentWithFiatPercentNotLessThanOneHundred'));

    const requestData = {
      ...data,
      isVisible: data?.isVisible ?? false,
      isActive: data?.isActive ?? false,
    };

    const [, err] = await createPaymentMethod(requestData);
    if (err) return showApiError(err);
    showSuccess(getText('successfullyCreatedPaymentMethod'));
    onRefresh();
    modalRef.current?.close();
    updateAllPaymentMethods({ pageIndex: 1, pageSize: 100 });
  };

  return (
    <>
      <div css={paymentMethodsCartContainer}>
        <div className="box">
          <div>
            <h4 className="add-title">{getText('addPaymentMethod')}</h4>
            <Button
              small
              type="secondary"
              leftIcon={<Icon iconName="add" size={20} className="icon" />}
              className="add-button"
              onClick={() => modalRef.current?.open()}>
              {getText('add')}
            </Button>
          </div>
          <div className="yellow-box">
            <Image src={wallet} className="wallet-image" />
          </div>
        </div>
      </div>

      <AddEditPaymentMethodModal
        ref={modalRef}
        title={getText('createPaymentMethod')}
        handleSubmit={handleCreatePaymentMethod}
      />
    </>
  );
};

export default AddPaymentMethodCart;
