export const CANCELLATION_REASONS = [
  { reason: 'Personal reasons/Trip called off', value: 'Personal reasons/Trip called off', label: 'personalReason' },
  {
    reason: 'Found a different accommodation option',
    value: 'Found a different accommodation option',
    label: 'foundDifferentAccommodationOption',
  },
  {
    reason: 'Change in the number or needs of guests',
    value: 'Change in the number or needs of guests',
    label: 'changeNumberNeedsGuests',
  },
  { reason: 'Other', value: 'Other', label: 'other' },
];
