import { theme } from 'styles';

export const featureCartContainer = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 700,
  width: 700,
  border: `1px solid ${theme.gray150}`,
  padding: 36,
  borderRadius: 10,
  marginBottom: 32,

  '.feature-title': {
    fontSize: 22,
    color: theme.gray800,
    marginBottom: 32,
    fontWeight: 500,
  },

  '.card-container': {
    position: 'relative',
    width: '100%',
    marginBottom: 16,
  },

  '.add-button': {
    color: theme.black,
    fontWeight: 500,

    '.icon': {
      marginRight: 4,
    },
  },
};

export const modalStyles = {
  '.close-icon': {
    top: 32,
  },

  '.modal-title': {
    marginBottom: 24,
    borderBottom: `1px solid ${theme.gray150}`,
    paddingBottom: 16,
    fontWeight: 500,
    fontSize: 22,
  },

  '.scrollable-container': {
    height: 500,
    overflow: 'auto',
    zIndex: 1,

    '.payment-method-to-add-container': {
      marginBottom: 24,
    },

    '.not-have-bookings': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10,
      border: `0.5px solid ${theme.gray150}`,
      boxShadow: theme.boxShadowDark,
      backgroundColor: theme.white,
      padding: '20px 24px',
      position: 'relative',

      '.empty-icon': {
        width: 326,
        height: 229,
      },

      '.title': {
        fontWeight: 500,
        color: theme.gray800,
      },
    },
  },

  '.actions': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
