import { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Modal } from 'components';
import { removeModalContainer } from './styles';

const RemovePaymentMethodModal = forwardRef((props, ref) => {
  const { item, feature, onRemove } = props;
  const { getText } = useTranslations();
  const modalRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  const handleRemovePaymentMethod = () => {
    onRemove();
    modalRef.current?.close();
  };

  return (
    <Modal ref={modalRef} closeIcon css={removeModalContainer}>
      <div className="remove-payment-method-container">
        <Icon iconName="feedback" size={60} color="red" className="icon" />
        <h4>{getText('areYouSureRemovePaymentMethod', { paymentMethodName: item.name, featureName: feature })}</h4>
      </div>

      <div className="action-container">
        <Button className="button" type="secondary" onClick={() => modalRef.current?.close()}>
          {getText('no')}
        </Button>
        <Button className="button" onClick={handleRemovePaymentMethod}>
          {getText('yes')}
        </Button>
      </div>
    </Modal>
  );
});

RemovePaymentMethodModal.propTypes = {
  item: PropTypes.object,
  feature: PropTypes.string,
  onRemove: PropTypes.func,
};

export default RemovePaymentMethodModal;
