import { keyframes } from '@emotion/react';
import { commonStyles, theme } from 'styles';

const textColorMap = {
  primary: theme.gray800,
  secondary: theme.secondaryDark,
  default: theme.gray500,
  warning: theme.gray500,
  link: theme.gray500,
  info: theme.gray800,
};

const backgroundColorMap = {
  primary: theme.primary,
  secondary: theme.secondary,
  default: 'transparent',
  danger: theme.error,
  success: theme.success,
  warning: theme.warning,
  info: theme.infoLight,
  link: 'transparent',
};

const borderColorMap = {
  secondary: 'transparent',
  link: 'transparent',
  default: theme.gray100,
};

const hoverBackgroundColorMap = {
  primary: theme.primaryDark,
  secondary: theme.secondaryLight,
  default: theme.gray100,
  danger: theme.errorDark,
  success: theme.successLight,
  warning: theme.warningLight,
  info: theme.info,
  link: 'transparent',
};

const hoverBorderColorMap = {
  secondary: theme.secondaryDark,
  link: 'transparent',
};

export const buttonContainer = (props, isLoading) => {
  const {
    type = 'primary',
    disabled,
    color,
    borderColor,
    backColor,
    hoverBackColor,
    hoverBorderColor,
    fullWidth,
    small,
  } = props;
  const backgroundColor = theme[backColor] ?? backgroundColorMap[type] ?? theme.primary;
  const hoverBackgroundColor = theme[hoverBackColor] ?? hoverBackgroundColorMap[type] ?? theme.primaryDark;
  const textColor = theme[color] ?? textColorMap[type] ?? theme.white;
  const borderColorDefault = theme[borderColor] ?? borderColorMap[type] ?? backgroundColor;
  const borderColorHover = theme[hoverBorderColor] ?? hoverBorderColorMap[type] ?? hoverBackgroundColor;
  const isLink = type === 'link';

  return [
    {
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: small ? '0.3em 0.8em' : '0.9em 1.6em',
      width: fullWidth ? '100%' : 'auto',
      minHeight: small ? '4.1rem' : '4.8rem',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 400,
      color: textColor,
      borderRadius: isLink ? 0 : 3,
      lineHeight: 1,
      border: `1px solid ${borderColorDefault}`,
      opacity: isLoading || disabled ? 0.4 : 1,
      backgroundColor,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      transition:
        'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, width .4s ease-in-out',

      '&:hover': {
        borderColor: borderColorHover,
        backgroundColor: hoverBackgroundColor,
      },

      '& > i': { marginRight: 6 },

      '.loading-button': {
        display: 'flex',
        alignItems: 'center',
        filter: 'blur(2px) !important',
      },
    },
    (disabled || isLoading) && {
      '& *': { pointerEvents: 'none' },
    },
    ...commonStyles(props),
  ];
};

export const checkButtonContainer = (hasLabel, isChecked, props) => [
  {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: props?.radio ? '50%' : 5,
    border: `1px solid ${theme.gray150}`,
    padding: props?.radio ? 1 : 2,
    backgroundColor:
      isChecked && !props?.radio ? theme.blueDark : isChecked && props?.radio ? theme.blue : 'transparent',
    transition: 'all 0.4s ease-in-out',
    width: 22,
    height: 22,
  },

  hasLabel && {
    '& > .input-indicator': {
      marginRight: 8,
      flexShrink: 0,
    },
    '& > .label': {
      display: 'inline-flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      marginLeft: 8,
    },
  },
  ...commonStyles(props),
];

export const checkButtonIcon = (isChecked) => ({
  opacity: isChecked ? 1 : 0,
  transition: 'all 0.4s ease-in-out',
});

export const checkButtonContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& > .label': {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'normal',
    marginLeft: 8,
    fontSize: 13,
    fontWeight: 400,
    color: theme.gray,
  },
};

export const errorStyles = {
  color: theme.error,
  fontSize: 11.5,
};

export const helpContent = {
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: 8,
};

export const helpIcon = {
  marginRight: 3,
  width: 'auto',
  height: '1rem',
  maxHeight: '100%',
};

const switchButtonStartAnimation = (size) => keyframes`
  0% {
    right: ${size.padding}px;
    width: ${size.smallWidth};
  }
  25% {
    right: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
  }
  50% {
    right: auto;
    width: calc(100% - ${size.padding * 2}px);
    left: ${size.padding}px;
  }
  100% {
    left: ${size.padding}px;
    width: ${size.smallWidth};
  }
`;

const switchButtonEndAnimation = (size) => keyframes`
  0% {
    left: ${size.padding}px;
    width: ${size.smallWidth};
  }
  25% {
    left: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
  }
  50% {
    right: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
    left: auto;
  }
  100% {
    right: ${size.padding}px;
    width: ${size.smallWidth};
  }
`;

export const switchToggle = (size, color, isChecked) => ({
  cursor: 'pointer',
  width: size.width * 2,
  height: size.width,
  display: 'inline-flex',
  position: 'relative',
  backgroundColor: theme.white,
  textAlign: 'left',
  borderRadius: size.width,
  boxSizing: 'border-box',
  perspective: 300,
  border: `1px solid ${isChecked ? theme[color] ?? theme.primary : theme.gray200}`,
  transition: 'border .8s ease-in-out',

  '&:before': {
    content: "''",
    width: size.smallWidth,
    height: size.smallWidth,
    position: 'absolute',
    right: size.padding,
    top: size.padding - 1,
    display: 'inline-flex',
    textAlign: 'center',
    borderRadius: size.smallWidth,
    transition: 'all 1s ease-in-out',
    transformOrigin: '0% 50%',
    transform: 'rotateY(0deg)',
    animation: `${
      isChecked ? switchButtonEndAnimation(size) : switchButtonStartAnimation(size)
    } 0.6s ease-in-out forwards`,
    backgroundColor: isChecked ? theme[color] ?? theme.primary : theme.gray100,
  },
});

export const switchContainer = {
  '.switch-content-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.label-left': {
      marginRight: 16,
    },

    '.label-right': {
      marginLeft: 40,
    },
  },
};
