import { TableActions } from 'components';
import { linkButton } from './styles';

export const allProfilesColumns = (getText) => [
  {
    name: getText('name'),
    render: ({ firstName, lastName }) => <p>{`${firstName} ${lastName}`}</p>,
  },
  {
    name: getText('userId'),
    value: 'id',
  },
  {
    name: getText('username'),
    value: 'userName',
  },
  {
    name: getText('email'),
    value: 'email',
  },
  {
    name: 'Actions',
    render: (profile) => (
      <TableActions
        mainAction={{
          type: 'link',
          linkTo: { pathname: `/profiles/details/${profile?.id}`, state: { profile } },
          children: getText('viewDetails'),
          css: linkButton,
        }}
      />
    ),
    width: '110px',
  },
];
