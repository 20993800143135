import { theme } from 'styles';

export const cancelBookingModalContainer = {
  '.close-icon': {
    padding: 4,
    backgroundColor: theme.gray100,
    borderRadius: '50%',
  },

  '.title': {
    marginBottom: 16,
  },

  '.reason-container': {
    marginBottom: 24,

    '.reasons': {
      display: 'flex',
      flexDirection: 'row',
      padding: '12px 24px',
    },
  },

  '.action-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '.button': {
      fontWeight: 600,
    },
  },
};
