import { theme } from 'styles';
import vector from 'assets/images/vector.svg';

export const paymentCartContainer = (backgroundColor, amountColor, iconHeight) => ({
  backgroundColor: theme[backgroundColor] ?? backgroundColor ?? theme.secondary,
  backgroundImage: `url(${vector})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 100% bottom 80%',
  backgroundSize: 'cover',
  padding: '12px 24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 10,
  width: '100%',
  marginBottom: 24,

  '.title': {
    fontWeight: 600,
    color: theme.gray300,
    textTransform: 'upperCase',
  },

  '.amount': {
    fontWeight: 500,
    color: theme[amountColor] ?? amountColor ?? theme.gray800,
  },

  '.icon': {
    width: 64,
    height: iconHeight ?? 107,
    mixBlendMode: 'multiply',
  },
});
