import { theme } from 'styles';

export const bookingRoomCardContainer = {
  padding: '32px 24px',
  borderRadius: 10,
  border: `1px solid ${theme.gray150}`,
  boxShadow: theme.boxShadowDark,
  marginBottom: 40,

  '.room-container': {
    marginBottom: 32,

    '.carousel-styles': {
      borderRadius: 8,
      height: 185,
    },

    '.room-info': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      height: '100%',

      '.room-title': {
        marginBottom: 4,
      },

      '.room-board-type': {
        marginBottom: 8,
        color: theme.gray700,
      },

      '.square-footage': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.gray300,
        fontWeight: 500,

        '.icon-room': {
          marginRight: 8,
        },
      },

      '.dot': {
        width: 5,
        height: 5,
        backgroundColor: theme.gray150,
        borderRadius: 50,
        margin: '0 8px',
      },
    },

    '.button': {
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.blue,
      fontWeight: 600,

      '&:active': { outline: 'none' },
    },
  },

  '.refund-container': {
    backgroundColor: theme.secondary,
    padding: 24,
    borderRadius: 10,
    marginBottom: 32,

    '.refund-title': {
      color: theme.gray700,
      marginBottom: 12,
      fontWeight: 500,
    },

    '.refund-desc': {
      color: theme.gray400,
      fontSize: 16,
    },
  },

  '.guests-rooms-styles': {
    color: theme.gray600,
    margin: '4px 8px',
  },
};
