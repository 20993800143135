import { theme } from 'styles';
export const footerContainer = {
  fontSize: 11,
  height: 40,
  zIndex: 0,
  borderTop: `1px solid ${theme.gray150}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.text': {
    color: theme.gray400,
    fontWeight: 500,
  },

  '.button': {
    fontWeight: 500,
    color: theme.gray400,
    padding: '0px 18px',

    '&:active': { outline: 'none' },
  },
};
