import { useState } from 'react';
import { softLogout } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Image } from 'components';
import { useUserStore } from 'stores';
import avatarLogo from 'assets/images/avatar.svg';
import { container, profileNavStyles } from './styles';

const User = () => {
  const [openContainer, setOpenContainer] = useState(false);
  const user = useUserStore();
  const { getText } = useTranslations();

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpenContainer(false);
  };

  return (
    <div role="button" tabIndex={0} onClick={() => setOpenContainer(true)} onBlur={hideContainer}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()} css={profileNavStyles}>
        <Image
          src={user?.userInfo?.pictureUrl ?? user?.userInfo?.picThumbnailUrl ?? avatarLogo}
          className="avatar-image"
        />
        <p>{user?.firstName}</p>
        <Icon material iconName="arrow_drop_down" size={16} />
      </div>

      {openContainer && (
        <div css={container}>
          <Button small type="default" onClick={softLogout} className="button">
            {getText('signOut')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default User;
