import { theme } from 'styles';

export const tableHeaderContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.inputs-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 900,
    width: '100%',

    '.input-options-dropdown': {
      marginTop: 0,
      height: 48,
      flex: 0,

      '.select-dropdown': {
        backgroundColor: theme.white,
        borderRadius: '10px 0 0 10px',
        border: `1px solid ${theme.gray150}`,
        minHeight: '4.5rem',
      },
    },

    '.search-input': {
      marginRight: 24,
      width: '100%',
      flex: 1,

      '.input': {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 8,
        height: 45,
        borderRadius: '0 10px 10px 0',
      },

      '@media (max-width: 767px)': {
        marginRight: 0,
      },
    },

    '@media (max-width: 767px)': {
      margin: '0 2em 0 0',
    },

    '@media (max-width: 1199px)': {
      marginBottom: 8,
    },
  },

  '.right-filters-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.statuses-dropdown': {
      borderRadius: 9,
      marginRight: 12,
      marginTop: 0,

      '.select-dropdown': {
        backgroundColor: theme.white,
        borderRadius: 10,
        border: `1px solid ${theme.gray150}`,
      },

      '@media (max-width: 767px)': {
        marginRight: 0,
        marginBottom: 8,
      },
    },

    '.date-range-picker': {
      height: '3.7rem',

      '.picker-container': {
        backgroundColor: theme.white,
        borderRadius: 10,
        border: `1px solid ${theme.gray150}`,
        width: 220,

        '.DateRangePickerInput': {
          backgroundColor: theme.white,
          borderRadius: 0,
          border: 'none',
          height: 37,

          '.DateInput': {
            '.DateInput_input': {
              backgroundColor: theme.white,
            },

            '.DateInput_input__small': {
              padding: '8px 0 8px 8px',
            },
          },
        },

        '@media (max-width: 767px)': {
          width: '100%',
        },
      },

      '.clear-icon': {
        '@media (max-width: 767px)': {
          right: -20,
        },
      },

      '@media (max-width: 767px)': {
        marginBottom: 8,
      },
    },

    '.vertical-line': {
      margin: '0 2em 0 3em',
      borderLeft: `2px solid ${theme.gray200}`,
      height: 30,

      '@media (max-width: 767px)': {
        margin: '0 2em 0 0',
      },
    },

    '.default-button': {
      borderColor: theme.gray150,
      padding: 8,
      borderRadius: 9,
      fontWeight: 500,
      minHeight: '3.7rem',

      '.button-icon': {
        marginRight: 4,
      },
    },

    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },
  },

  '@media (max-width: 1199px)': {
    flexWrap: 'wrap',
  },
};
