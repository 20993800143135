import { useLayoutEffect, useState, useImperativeHandle } from 'react';
import { isFunction, isPlainObject, isArray, pick, isNil } from 'lodash-es';
import { useUrlParams, useDeepEffect } from '../hooks';

const extractParams = (queryParams, keys) => {
  const parametersKeys = isPlainObject(keys) ? Object.values(keys) : isArray(keys) ? keys : [keys];
  return { pageIndex: queryParams.pageIndex, pageSize: queryParams.pageSize, ...pick(queryParams, parametersKeys) };
};

const defaultPageSizes = [10, 20, 50];

export const usePagination = ({
  onChange,
  pageIndex: pageIndexProp,
  pageSize: pageSizeProp,
  pageSizes,
  filterKeys,
  requiredFilterKeys,
  fetchOnMount = true,
  defaultQueryParams,
  ref,
}) => {
  const { queryParams, setQueryParams, clearQueryParams } = useUrlParams();
  const [totalResults, setTotalResults] = useState(null);

  const queryPageIndex = queryParams.pageIndex ?? 1;
  const queryPageSize = queryParams.pageSize ?? pageSizes?.at(0) ?? defaultPageSizes.at(0);
  const totalPages = Math.ceil(totalResults / queryPageSize) || 1;

  const params = extractParams(queryParams, filterKeys);
  const requiredParams = extractParams(queryParams, requiredFilterKeys);
  const hasRequiredParams = !Object.values(requiredParams).some((el) => isNil(el) || el === '');

  useImperativeHandle(ref, () => ({
    pageIndex: queryPageIndex,
    pageSize: queryPageSize,
    totalResults,
    changePageIndex: handlePageChange,
    changePageSize: handlePageSizeChange,
    changeTotalResults: handleTotalResultsChange,
    applyFilters: handleApplyFilters,
    resetFilters: handleResetFilters,
    refresh: callOnChange,
  }));

  useLayoutEffect(() => {
    setQueryParams({
      ...defaultQueryParams,
      ...queryParams,
      pageIndex: pageIndexProp ?? queryPageIndex,
      pageSize: pageSizeProp ?? queryPageSize,
    });
  }, [pageIndexProp, pageSizeProp]);

  useDeepEffect(() => {
    // Pagination could be used with or without filters. So we need to call onChange method to fetch the first page
    // If there are some required params this effect will wait those params to be filled and after that will call onChange
    hasRequiredParams && fetchOnMount && callOnChange();
  }, [params]);

  const handlePageChange = (newPageIndex) => setQueryParams({ pageIndex: newPageIndex, pageSize: queryPageSize });

  const handlePageSizeChange = (newPageSize) => setQueryParams({ pageIndex: queryPageIndex, pageSize: newPageSize });

  const handleTotalResultsChange = (res) => {
    const total = res?.totalResults ?? 0;
    setTotalResults(total);
    const resultTotalPages = Math.ceil(total / queryPageSize) || 1;
    resultTotalPages < queryPageIndex && handlePageChange(resultTotalPages);
  };

  const handleApplyFilters = (newParams) => setQueryParams({ ...newParams, pageIndex: 1 });

  const handleResetFilters = () => {
    setQueryParams({ pageIndex: 1 });
    clearQueryParams(extractParams(queryParams, filterKeys));
  };

  const callOnChange = async () => {
    if (!isFunction(onChange)) return;
    const res = await onChange(params);
    handleTotalResultsChange(res);
  };

  return {
    totalResults,
    totalPages,
    pageIndex: queryPageIndex,
    pageSize: queryPageSize,
    handlePageChange,
    handlePageSizeChange,
  };
};
