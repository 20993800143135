import { theme } from 'styles';

export const paymentMethodsCartContainer = {
  height: '100%',
  minHeight: 220,

  '.box': {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    background: `linear-gradient(270deg, ${theme.infoLight} 25%, ${theme.secondaryLightDark} 100%)`,
    color: theme.gray500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    boxShadow: theme.boxShadow,
    padding: 16,
    cursor: 'pointer',
  },
};

export const modalStyles = {
  '.title': { marginBottom: 24 },

  '.rows': {
    marginBottom: 24,

    '.subscription-type-dropdown': {
      '.select-dropdown': {
        '& > span': {
          marginTop: 12,
        },
      },
    },
  },

  '.actions': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
