import { Children } from 'react';
import { isArray, isString, isEqual, isNil, isPlainObject, isEmpty } from 'lodash-es';
import { isStrNum } from '../utils';
import validateInput from '../Inputs/inputValidation';

export const getFormInputs = (children) => {
  const newChildrens = [];
  const getFlatList = (childs) => {
    Children.forEach(childs, (child) => {
      const childChildren = Children.toArray(child?.props?.children);
      childChildren.length ? getFlatList(child?.props?.children) : child?.props?.formId && newChildrens.push(child);
    });
  };
  getFlatList(children);
  return newChildrens;
};

// Check if the values are defined because lodash isEqual with 2 undefined returns true
const compareValues = (source, value, keys) => {
  // Compare string with object if they are related to the same thing
  // Example: code as a string and object with code property if the codes are same they are equal
  if (isStrNum(source) && isPlainObject(value)) return keys.some((key) => source === value[key]);
  if (isPlainObject(source) && isStrNum(value)) return keys.some((key) => value === source[key]);

  // Compare two objects, if they have match
  const areDef = (key) => !isNil(source[key]) && !isNil(value[key]);
  const areSame = (key) => isEqual(source[key], value[key]);
  const compare = (key) => areDef(key) && areSame(key);
  if (isPlainObject(source) && isPlainObject(value)) return keys.some(compare);

  const hasSort = (item) => (isArray(item) ? [...item].sort() : item);

  return isEqual(hasSort(source), hasSort(value));
};

const noValue = (val) => isNil(val) || ((isString(val) || isArray(val) || isPlainObject(val)) && isEmpty(val));

export const areEqual = (val, initValue, correspondChild) => {
  if (isNil(initValue) && noValue(val)) return true;

  if (!correspondChild) return null;

  const { displayKey = 'label', uniqueKey = 'value' } = correspondChild.props;
  const keys = [displayKey, uniqueKey];

  return compareValues(initValue, val, keys);
};

export const requiredFieldValidation = (val, child) => {
  switch (child?.type?.displayName) {
    case 'Input':
      return validateInput('required')(val);
    case 'FileInput':
      return validateInput('required')(child.props.multiple ? (val.length ? val.length : '') : val?.data);
    case 'DateTimePicker':
      return validateInput('required')(val);
    case 'Dropdown':
      return isStrNum(val)
        ? validateInput('required')(val)
        : isEmpty(val) && {
            msg: `${child?.props?.label ?? 'Field'} is required!`,
          };
    case 'PhoneInput':
      return isStrNum(val)
        ? validateInput(['required', 'phone'])(val)
        : validateInput('required')(val?.input) ||
            validateInput('required')(val?.phone) ||
            validateInput('phone')(`${val?.phone} ${val?.input}`);
    case 'CheckBox':
      return (
        !val && {
          msg: `${child?.props?.label ?? 'Field'} is required!`,
        }
      );
    default:
      return null;
  }
};
