import { differenceBy } from 'lodash-es';
import { useStore, createStore, showError } from 'components';
import { RESTRICTED_COUNTRIES } from 'enums';
import { getAllCountries } from 'services';

const countriesStore = createStore({ all: null, allWithoutRestricted: null });

export const initCountries = async () => {
  const [res, err] = await getAllCountries();
  if (err) showError(err);

  countriesStore.setState({ all: res, allWithoutRestricted: differenceBy(res, RESTRICTED_COUNTRIES, 'id') });
};

export const useCountries = (...args) => useStore(countriesStore, ...args);
