import { invert } from 'lodash-es';

export const TRANSACTION_TYPE = {
  0: 'Credit',
  1: 'Debit',
};

export const TRANSACTION_TYPE_INVERSE = invert(TRANSACTION_TYPE);

export const TRANSACTION_STATUS_TYPE = {
  Completed: 'Completed',
  ProcessingPayment: 'ProcessingPayment',
  ProcessingRefund: 'ProcessingRefund',
  PaymentFailed: 'PaymentFailed',
  Rejected: 'Rejected',
};

export const TRANSACTION_STATUS_TYPE_INVERSE = invert(TRANSACTION_STATUS_TYPE);

export const TRANSACTION_KINDS = {
  // SUBSCRIPTION: 'Subscription',
  BOOKING_PAYMENT: 'BookingPayment',
  BOOKING_REFUND: 'BookingRefund',
};

export const TRANSACTION_SUB_KINDS = {
  BOOKING_PAYMENT_WITH_CASH: 'BookingPaymentWithCash',
  BOOKING_PAYMENT_WITH_CRYPTO: 'BookingPaymentWithCrypto',
  BOOKING_REFUND_OF_CASH: 'BookingRefundOfCash',
  BOOKING_REFUND_OF_CRYPTO: 'BookingRefundOfCrypto',
  OTHER: 'Other',
};

export const TRANSACTION_STATUS_COLOR = {
  [TRANSACTION_STATUS_TYPE.Completed]: {
    color: 'success',
  },
  [TRANSACTION_STATUS_TYPE.ProcessingPayment]: {
    color: 'info',
  },
  [TRANSACTION_STATUS_TYPE.ProcessingRefund]: {
    color: 'pink',
  },
  [TRANSACTION_STATUS_TYPE.PaymentFailed]: {
    color: 'error',
  },
  [TRANSACTION_STATUS_TYPE.Rejected]: {
    color: 'red',
  },
};

export const TRANSACTIONS_SORTING_OPTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};
