import { theme } from 'styles';

export const subscriptionContainer = (isRecommended) => [
  {
    padding: isRecommended ? '48px 24px 24px' : '48px 24px 24px 24px',
    borderRadius: 20,
    backgroundColor: theme.white,
    color: theme.black,
    position: 'relative',
    flex: 1,
    boxShadow: theme.boxShadowDark,
    height: '100%',

    '.recommended-container': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.infoLightDarker,
      borderRadius: '20px 20px 0 0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 8,

      '.recommended-icon': {
        marginRight: 8,
      },

      '.recommended-text': {
        color: theme.blueDarker,
        fontWeight: 500,
      },
    },

    '.plan': {
      fontSize: 18,
      marginBottom: 16,
    },

    '.per-text': {
      color: theme.gray400,
      marginBottom: 24,
    },

    '.active-visible-container': {
      fontWeight: 500,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: 8,

      '.row-info': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,

        '&:first-of-type': {
          marginRight: 24,
        },
      },
    },

    '.includes': {
      color: theme.black,
      marginBottom: 16,
      fontSize: 16,
    },

    '.button': {
      width: '100%',
      borderRadius: 8,
      color: theme.black,
      fontWeight: 500,
      fontSize: 16,
    },
  },
];

export const benefits = (isIncluded, hasBvPoints) => [
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    color: isIncluded ? theme.black : hasBvPoints ? theme.blue : theme.secondaryLightDark,
    fontWeight: hasBvPoints && 600,

    '& > p': {
      textDecoration: isIncluded ? 'none' : 'line-through',
    },

    '.bolded': {
      fontWeight: 600,
      marginRight: 4,
    },

    '.icon': {
      padding: !isIncluded && 4,
      marginRight: !isIncluded && 4,
    },
  },
];
