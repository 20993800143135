export const editSubscriptionCatalogueContainer = {
  '.plan': {
    fontSize: 18,
  },

  '.rows': {
    marginBottom: 24,
  },

  '.actions': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
