import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { BackButton, Col, Icon, Row, showApiError, WalletTransactionsCart, BookingCart } from 'components';
import { BOOKING_TYPES } from 'enums';
import { getUserById } from 'services';
import { profileDetailsContainer } from './styles';

const ProfileDetails = () => {
  const { getText } = useTranslations();
  const { userId } = useParams();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    fetchTransactionDetails();
    handleScrollToTop();
  }, []);

  const fetchTransactionDetails = async () => {
    const [res, err] = await getUserById(userId);

    err ? showApiError(err) : setProfile(res);
  };

  const handleScrollToTop = () =>
    document
      .getElementById('#profile-details-page')
      .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  return (
    <div css={profileDetailsContainer} id="#profile-details-page">
      <div className="header-container">
        <BackButton className="back-button" />

        <div className="header-info-container">
          <div className="icon-container">
            <Icon iconName="account_circle" color="white" size={20} />
          </div>
          {profile?.firstName && profile?.lastName && (
            <p className="bolded-title">{`${profile.firstName} ${profile.lastName}`}</p>
          )}
          {profile?.userId && <p className="gray-text">#{profile?.userId}</p>}
        </div>
      </div>
      <Row>
        <Col sm={12} md={12} lg={6}>
          <WalletTransactionsCart
            transactions={profile?.transactions}
            navigateTo={`/transactions?userId=${profile.userId}`}
          />

          <h4 className="title-styles">{getText('latestBookings')}</h4>
          <BookingCart
            bookings={profile.bookings}
            bookingType={BOOKING_TYPES.HOTEL}
            navigateTo={`/bookings?email=${profile.email}`}
          />
        </Col>
        <Col sm={12} md={12} lg={1} />
        <Col sm={12} md={12} lg={5}>
          <h4 className="user-styles">{getText('user')}</h4>

          <div className="user-container">
            <div className="user-info-container">
              {profile?.firstName && profile?.lastName && (
                <p className="blue-text">{`${profile.firstName} ${profile.lastName}`}</p>
              )}
              {profile?.email && <p className="gray-text">{profile.email}</p>}
              {profile?.id && <p className="gray-text">{profile?.id}</p>}
            </div>
            {profile?.firstName && profile?.lastName && (
              <div className="user-names">
                {`${profile?.firstName?.substring(0, 1)}${profile?.lastName?.substring(0, 1)}`}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileDetails;
