import { useEffect } from 'react';
import { softLogout } from '@oneecosystem/authenticate';
import { Spinner } from 'components';

const Logout = () => {
  useEffect(() => {
    softLogout();
  }, []);

  return <Spinner fullScreen />;
};

export default Logout;
