import PropTypes from 'prop-types';
import { footerContainer } from './styles';

const Footer = (props) => {
  const { className } = props;
  const getYear = new Date().getFullYear();

  return (
    <div css={footerContainer} {...(className && { className })}>
      <p className="text">
        &nbsp; © {getYear} <span>OneVoyage, Inc. All Rights Reserved</span>
      </p>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
