import { useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { Tabs, Tab } from 'components';
import { SUBSCRIPTION_TYPE } from 'enums';
import { initSubscriptionTypes } from 'stores';
import SubscriptionCatalogues from './SubscriptionCatalogues';
import SubscriptionTypes from './SubscriptionTypes';
import { subscriptionContainer } from './styles';

const SubscriptionPage = () => {
  const { getText } = useTranslations();

  useEffect(() => {
    initSubscriptionTypes();
  }, []);

  return (
    <div css={subscriptionContainer}>
      <h3 className="title-section">{getText('subscriptions')}</h3>

      <Tabs noAnimation>
        <Tab
          component={() => <SubscriptionCatalogues type={SUBSCRIPTION_TYPE.CATALOGUE} />}
          label={getText('subscriptionsCatalogues')}
        />
        <Tab
          component={() => <SubscriptionTypes type={SUBSCRIPTION_TYPE.TYPE} />}
          label={getText('subscriptionTypes')}
        />
      </Tabs>
    </div>
  );
};

export default SubscriptionPage;
