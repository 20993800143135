import { theme } from 'styles';

export const checksPetsContainer = (allowed, isEmptyPolicy) => ({
  width: '100%',
  marginBottom: 32,

  '.checks-icon': {
    marginBottom: 24,
  },

  '.checks-gray-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    wordBreak: 'break-word',
    padding: 24,
    backgroundColor: theme.gray50,
    borderRadius: 10,
    width: '100%',
    minWidth: 240,

    '.check-date': {
      color: theme.gray400,
    },

    '.vertical-line': {
      borderLeft: `1px solid ${theme.gray200}`,
      margin: '0 24px ',
    },
  },

  '.checks-pets-container': {
    padding: 24,
    color: isEmptyPolicy ? theme.gray400 : allowed ? theme.successDark : theme.errorDark,
    backgroundColor: isEmptyPolicy ? theme.gray50 : allowed ? theme.successLighter : theme.errorLight,
    borderRadius: 10,
    fontWeight: 500,

    '.title': {
      color: theme.gray800,
    },

    '.description': {
      fontWeight: 400,
      color: theme.black,
    },
  },
});
