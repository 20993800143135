import { useState } from 'react';
import PropTypes from 'prop-types';
import { camelCase, isArray } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { CheckButton, Button } from 'components';
import { container } from './styles';

const LabelCountCheck = (props) => {
  const { labelFilter, options, selectedOptions, showResults = 3, onChange, className } = props;
  const { getText } = useTranslations();
  const [showMore, setShowMore] = useState(!!options.length > showResults);
  const allElements = showMore ? options : options?.slice(0, showResults);

  const label = (filterLabel, el) => {
    switch (true) {
      case filterLabel === 'bedsCount':
        return <p className="description">{`${el?.id} ${getText('beds')}`}</p>;
      default:
        return <p className="description">{getText(`${camelCase(el?.name)}`)}</p>;
    }
  };

  return (
    <div {...(className && { className })}>
      {allElements.map((el, ind) => (
        <div key={ind} css={container} className="item">
          <div className="label">
            {labelFilter ? label(labelFilter, el) : <p className="description">{getText(`${camelCase(el?.name)}`)}</p>}
            <span className="count">{el?.count}</span>
          </div>
          <CheckButton
            value={
              isArray(selectedOptions)
                ? selectedOptions?.find((element) => element === el?.id)
                : selectedOptions === el?.name
            }
            onChange={(value) => (isArray(selectedOptions) ? onChange(el) : onChange(value ? el : null))}
          />
        </div>
      ))}
      {options.length > showResults && (
        <Button
          small
          type="link"
          onClick={() => setShowMore(!showMore)}
          className="link-button"
          rightIcon={{ iconName: !showMore ? 'expand_more' : 'expand_less', material: true }}>
          {getText(!showMore ? 'showMore' : 'showLess', { count: options.length - showResults })}
        </Button>
      )}
    </div>
  );
};

LabelCountCheck.propTypes = {
  labelFilter: PropTypes.string,
  options: PropTypes.array,
  showResults: PropTypes.number,
  selectedOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default LabelCountCheck;
