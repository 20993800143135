import { theme } from 'styles';

export const transactionCommentsContainer = {
  padding: '28px 32px',
  borderRadius: 10,
  border: `0.5px solid ${theme.gray150}`,
  marginBottom: 32,

  '.no-comments-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,

    '.no-comments-icon': {
      width: 75,
      height: 60,
      marginRight: 16,
    },
  },

  '.comments': {
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',

    '.names': {
      borderRadius: '50%',
      backgroundColor: theme.blue,
      color: theme.white,
      padding: 4,
      marginRight: 24,
      textTransform: 'uppercase',
    },

    '.comment-container': {
      display: 'flex',
      flexDirection: 'column',

      '.user-date': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,

        '.comment-user': {
          fontSize: 16,
          marginRight: 8,
        },

        '.comment-date': {
          color: theme.gray400,
        },
      },

      '.comment': {
        fontSize: 14,
        color: theme.gray800,
      },
    },
  },

  '.comment-input': {
    position: 'relative',
    marginBottom: 8,

    '.name-container': {
      borderRadius: '50%',
      backgroundColor: theme.blue,
      color: theme.white,
      padding: 4,
      position: 'absolute',
      top: 20,
      left: 6,
    },

    '.input': {
      paddingLeft: 40,
    },
  },

  '.buttons-container': {
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'flex-end',

    '.save-button': {
      color: theme.gray800,
      fontWeight: 600,
    },

    '.cancel-button': {
      color: theme.gray800,
      fontWeight: 600,
      marginRight: 16,
    },
  },
};
