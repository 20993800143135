import { keyframes } from '@emotion/react';
import { theme } from 'styles';

const lock = keyframes`
  50% {top:-4%;}
	100% {top:-6%;}
`;

const colors = keyframes`
  50% {transform:scale(1.1);}
  100% {color:${theme.blueDarkLight}}
`;

export const mainContainer = {
  width: '100%',
  height: 'calc(100vh - 40px)',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: `linear-gradient(180deg, ${theme.infoLight} 0%, ${theme.blue} 100%)`,

  'div[role=button]': {
    color: 'rgb(162,163,166)',
  },

  h1: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '30vw',
    display: 'block',
    margin: '0',
    color: '#9ae1e2',
    position: 'relative',
    zIndex: '0',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '1.7s',

    '&:before': {
      content: '"U"',
      position: 'absolute',
      top: '-9%',
      right: '40%',
      transform: 'rotate(180deg)',
      fontSize: '15vw',
      color: theme.primary,
      zIndex: '-1',
      textAlign: 'center',
      animation: `${lock} .2s ease-in-out forwards`,
      animationDelay: '1.5s',
    },
  },

  h2: {
    color: '#9ae1e2',
    fontSize: '5vw',
    margin: '0',
    textTransform: 'uppercase',
    textAlign: 'center',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '2s',
  },
};
