import { commonStyles, theme } from 'styles';

export const searchBarContainer = (props) => [
  {
    position: 'relative',
    zIndex: 50,
    width: '100%',
    borderRadius: 24,
  },
  ...commonStyles(props),
];

export const searchBarInputContainer = (props) => [
  {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 25,
    minHeight: '4.8rem',
    width: '100%',
    backgroundColor: theme.gray50,
    border: `1px solid ${theme.gray100}`,
  },
  ...commonStyles(props),
];

export const searchBarInput = {
  fontSize: '1.3rem',
  lineHeight: 1.2,
  paddingRight: '1rem',
  outline: 'none',
  padding: '0.8rem 1rem',
  border: 'none',
  background: 'transparent',
  borderRadius: 6,
  width: '100%',

  '&:focus, &:active': {
    border: 'none',
    boxShadow: 'none',
  },
};

export const searchBarInputIcon = {
  cursor: 'pointer',
  padding: '0.8rem 1rem 0.8rem 1rem',
  color: theme.gray300,
};

export const searchBarSuggestionsContainer = {
  position: 'absolute',
  top: 'calc(100% + 8px)',
  left: 0,
  right: 0,
  color: theme.gray200,
  maxHeight: 300,
  zIndex: 20,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  backgroundColor: theme.white,
  boxShadow: theme.boxShadow,
};

export const searchBarSuggestionItem = {
  padding: '0.8rem 1.5rem',
  color: theme.gray200,

  '&:focus, &:hover': {
    outline: 'none',
    backgroundColor: theme.gray50,
    color: theme.gray300,
  },
};
