import { theme } from 'styles';

export const paginationContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'nowrap',
  width: '100%',
  padding: 8,

  '& .pagination-dots': {
    margin: '0 0.25em',
  },

  '.shown-results-indicator': {
    color: theme.gray200,
    textAlign: 'center',
    fontSize: 11,
    marginRight: 12,
  },
};

export const paginationButton = (disabled, isActive) => [
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    width: 32,
    height: 32,
    margin: '0 2px',
    borderRadius: '50%',

    '&:hover': !disabled &&
      !isActive && {
        backgroundColor: theme.gray100,
      },
  },
  isActive && {
    backgroundColor: theme.infoLight,
  },
  disabled && {
    cursor: 'not-allowed',
    color: theme.gray300,
  },
];

export const pageSizeDropdown = {
  width: 80,
};

export const actionButton = (disabled, isActive) => [
  {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    userSelect: 'none',

    '.button-text': {
      color: theme.blue,
      fontSize: 14,
      fontWeight: 500,
    },

    '&:hover': !disabled &&
      !isActive && {
        backgroundColor: theme.gray100,
      },
  },

  isActive && {
    backgroundColor: theme.primaryLight,
  },

  disabled && {
    cursor: 'not-allowed',
    color: theme.gray300,
  },
];
