import queryString from 'query-string';
import { isString, pick } from 'lodash-es';

export const getAllUsersParams = (options = {}) => {
  const { userId, email, username } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(isString(userId) && { userId }),
      ...(isString(email) && { email }),
      ...(isString(username) && { username }),
    },
    { arrayFormat: 'index' },
  );
};
