import { invert } from 'lodash-es';

export const HOTEL_ENTERTAINMENT_SERVICES = {
  1: 'COMFORT',
  2: 'LUXURY',
  3: 'SPA_WELLNESS',
  4: 'BUSINESS_FRIENDLY',
};

export const HOTEL_ENTERTAINMENT_SERVICES_INVERSE = invert(HOTEL_ENTERTAINMENT_SERVICES);

export const HOTEL_ENTERTAINMENT_MAP = {
  [HOTEL_ENTERTAINMENT_SERVICES_INVERSE.COMFORT]: {
    icon: 'hotel_class',
    text: 'comfort',
    color: 'success',
  },
  [HOTEL_ENTERTAINMENT_SERVICES_INVERSE.BUSINESS_FRIENDLY]: {
    icon: 'work',
    text: 'businessFriendly',
    color: 'blue',
  },
  [HOTEL_ENTERTAINMENT_SERVICES_INVERSE.LUXURY]: {
    icon: 'diamond',
    text: 'luxury',
    color: 'warning',
  },
  [HOTEL_ENTERTAINMENT_SERVICES_INVERSE.SPA_WELLNESS]: {
    icon: 'spa',
    text: 'spaAndWellness',
    color: 'pink',
  },
};

export const HIGHLIGHTED_HOTEL_AMENITIES = [
  { name: 'free internet access', icon: 'wifi' },
  { name: 'internet', icon: 'wifi' },
  { name: 'free parking', icon: 'emoji_transportation' },
  { name: 'parking', icon: 'emoji_transportation' },
  { name: 'pets allowed', icon: 'pets' },
  { name: 'pet friendly', icon: 'pets' },
  { name: 'restaurant', icon: 'restaurant' },
  { name: 'garden', icon: 'deceased' },
  { name: 'secure parking', icon: 'local_parking' },
  { name: 'kitchen', icon: 'soup_kitchen' },
  { name: 'fitness center', icon: 'exercise' },
  { name: 'no smoking rooms', icon: 'smoke_free' },
  { name: 'non-smoking rooms', icon: 'smoke_free' },
  { name: 'spa', icon: 'spa' },
  { name: 'swimming pool', icon: 'pool' },
  { name: 'baggage storage', icon: 'luggage' },
  { name: 'daily housekeeping', icon: 'cleaning_services' },
  { name: 'dry cleaning', icon: 'dry_cleaning' },
  { name: 'room service', icon: 'room_service' },
  { name: 'entertainment area', icon: 'sports_esports' },
  { name: 'wi-fi', icon: 'wifi' },
  { name: 'wifi', icon: 'wifi' },
  { name: 'free wifi', icon: 'wifi' },
  { name: 'car rental', icon: 'car_rental' },
  { name: 'ironing service', icon: 'iron' },
  { name: 'bar', icon: 'local_bar' },
  { name: 'terrace', icon: 'balcony' },
  { name: 'playground', icon: 'attractions' },
  { name: 'elevator', icon: 'elevator' },
];

export const HOTEL_SORTING = {
  GUEST_SCORE: 'gs',
  STAR_RATING: 'sr',
  LOWEST_PRICE: 'lp',
  HIGHEST_PRICE: 'hp',
  DISTANCE: 'ds',
  MOST_POPULAR: 'mp',
};

export const HOTEL_SORTING_INVERSE = invert(HOTEL_SORTING);

export const HOTEL_BED_TYPES = {
  QUEEN: 'QUEEN',
  KING: 'KING',
  FULL: 'FULL',
  TWIN: 'TWIN',
  SOFABED: 'SOFABED',
};

export const HOTEL_GUEST_SCORE = {
  1: 'AWFUL',
  2: 'TERRIBLE',
  3: 'HORRIBLE',
  4: 'POOR',
  5: 'OKAY',
  6: 'PLEASANT',
  7: 'GOOD',
  8: 'VERY GOOD',
  9: 'EXCEPTIONAL',
  10: 'PERFECT',
};

export const HOTEL_GUEST_SCORE_INVERSE = invert(HOTEL_GUEST_SCORE);
