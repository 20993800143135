import { theme } from 'styles';

export const navBarHeaderContainer = {
  height: 50,
  background: `linear-gradient(180deg, ${theme.infoLight} 0%, ${theme.secondaryLightDarker} 100%)`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.3em 2em',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
};

export const navBarLeftSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  whiteSpace: 'nowrap',

  '& > svg': {
    height: 36,
    width: 'auto',
  },

  '& > *': {
    margin: '0 2rem 0 0',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
};

export const navBarRightSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',

  '& > *': {
    marginRight: 16,
    cursor: 'pointer',

    '&:last-child': {
      marginRight: 0,
    },
  },
};
