export const subscriptionContainer = {
  padding: '64px 100px 16px 100px',

  '.title-section': {
    marginBottom: 32,
  },

  '.cards-row': {
    marginBottom: 16,
  },
};

export const subscriptionTypeCataloguesContainer = {
  '.card-container': {
    position: 'relative',
  },
};
