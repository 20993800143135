import { capitalize } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { policyContainer } from './styles';

const PolicyCart = ({ policies, className }) => {
  const { getText } = useTranslations();

  return (
    <div css={policyContainer} {...(className && { className })}>
      {policies?.map((el, ind) => (
        <div key={ind} className="policy-cart">
          <h5 className="policy-title">{getText(el?.title)}</h5>
          <p className="paragraph">{capitalize(getText(el?.description))}</p>
          {ind !== policies?.length - 1 && <hr className="horizontal-line" />}
        </div>
      ))}
    </div>
  );
};

export default PolicyCart;
