import PropTypes from 'prop-types';
import { Icon } from 'components';
import { ReactComponent as EmptyIcon } from 'assets/images/not-have.svg';
import { noResultsContainer } from './styles';

export const NoResults = ({ emptyMessage }) => {
  return (
    <section css={noResultsContainer}>
      <EmptyIcon className="no-results-icon" />
      <p>{emptyMessage ?? 'No Data'}</p>
    </section>
  );
};

NoResults.propTypes = {
  emptyMessage: PropTypes.string,
};

export const Error = () => {
  return (
    <section css={noResultsContainer}>
      <div className="no-results-icon">
        <Icon material iconName="report" size={48} color="white" />
      </div>
      <h2 className="no-results-title">Request failure.</h2>
      <p className="no-results-subtitle">
        There was problem getting the results.
        <br />
        Please try again later.
      </p>
    </section>
  );
};
