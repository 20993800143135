import { differenceBy } from 'lodash-es';
import { useStore, createStore, showApiError } from 'components';
import { FEATURE_TYPES } from 'enums';
import { getAllPaymentMethods, getAllPaymentMethodsByFeature } from 'services';

const featuresStore = createStore({
  allPaymentMethods: [],
  bookingPaymentMethods: [],
  paymentMethodsToAdd: {},
});

export const updateAllPaymentMethods = async (options) => {
  const [res, err] = await getAllPaymentMethods(options);
  if (err) return showApiError(err);

  featuresStore.setState((prev) => ({
    ...prev,
    allPaymentMethods: res.results,
    paymentMethodsToAdd: {
      booking: differenceBy(res.results, prev?.bookingPaymentMethods, 'name'),
    },
  }));
};

export const initFeaturesPaymentMethods = async (options) => {
  const [resAllPaymentMethods, errAllPaymentMethods] = await getAllPaymentMethods(options);
  if (errAllPaymentMethods) return showApiError(errAllPaymentMethods);

  const [resBooking, errBooking] = await getAllPaymentMethodsByFeature(FEATURE_TYPES.BOOKING);
  if (errBooking) return showApiError(errBooking);

  featuresStore.setState((prev) => ({
    ...prev,
    allPaymentMethods: resAllPaymentMethods.results,
    bookingPaymentMethods: resBooking.results,
    paymentMethodsToAdd: {
      booking: differenceBy(resAllPaymentMethods.results, resBooking.results, 'name'),
    },
  }));
};

export const updatePaymentMethodsFeatures = (paymentMethods, allPaymentMethods) => {
  featuresStore.setState((prev) => ({
    ...prev,
    bookingPaymentMethods: paymentMethods,
    paymentMethodsToAdd: {
      ...prev.paymentMethodsToAdd,
      booking: differenceBy(allPaymentMethods, paymentMethods, 'name'),
    },
  }));
};

export const useFeaturesStore = (...args) => useStore(featuresStore, ...args);
