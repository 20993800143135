import { useState } from 'react';
import { isArray, isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Icon, Button, Form, Row, Col, Input, CheckButton, parseJson } from 'components';
import { editSubscriptionContainer } from './styles';

const EditSubscriptionTypeCart = ({ subscription, handleEdit, onUpdateSubscription }) => {
  const { getText } = useTranslations();
  const subscriptionIncludes = parseJson(subscription?.includes, []);
  const [includesItems, setIncludesItems] = useState(
    subscriptionIncludes?.map((el, ind) => ({ ...el, id: ind })) ?? [],
  );

  const formValues = includesItems?.reduce(
    (acc, el) => {
      acc.includes.push(el.label);
      acc.includesValue.push(el.strike);
      return acc;
    },
    { includes: [], includesValue: [] },
  );

  const handleIncludeSubmit = (val) => {
    const allIncludesItems =
      val.includes.length > includesItems.length || val.includesValue.length > includesItems.length
        ? includesItems.map((el) => ({ label: el.label, strike: el.strike || false }))
        : val.includes.map((el, ind) => ({ label: el, strike: val.includesValue[ind] || false }));
    const allIncludesItemsWithoutEmptyItems = allIncludesItems?.filter((el) => !isEmpty(el.label));

    onUpdateSubscription(subscription?.name, { includes: JSON.stringify(allIncludesItemsWithoutEmptyItems) });
  };

  return (
    <div css={editSubscriptionContainer}>
      <p className="plan">{getText(subscription?.name)}</p>
      <Form values={formValues} onSubmit={(val) => handleIncludeSubmit(val)}>
        <Row gap={8} className="rows">
          {isArray(includesItems) && includesItems?.length && (
            <Col sm={12} md={12}>
              {includesItems?.map((el, ind) => (
                <div key={ind} className="includes-container">
                  <Input formId={`includes.${ind}`} label={getText('subscriptionIncludes')} className="include-input" />
                  <CheckButton formId={`includesValue.${ind}`}>{getText('includeValue')}</CheckButton>

                  <Button
                    small
                    type="link"
                    onClick={() => setIncludesItems((prev) => prev?.filter((elem) => el.id !== elem.id))}
                    className="delete-button">
                    <Icon iconName="delete" color="infoDark" size={20} />
                  </Button>
                </div>
              ))}
            </Col>
          )}
          <Col sm={12} md={12}>
            <Button
              small
              type="secondary"
              onClick={() =>
                setIncludesItems((prev) => prev && [...prev, { label: '', strike: false, id: prev?.length + 1 }])
              }>
              <p>{getText('addMoreIncludes')}</p>
              <Icon iconName="add_circle" color="infoDark" size={32} />
            </Button>
          </Col>
        </Row>
        {({ onSubmit }) => (
          <div className="actions">
            <Button type="secondary" onClick={() => handleEdit(false)}>
              {getText('cancel')}
            </Button>
            <Button onClick={onSubmit}>{getText('submit')}</Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default EditSubscriptionTypeCart;
