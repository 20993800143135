import { theme, commonStyles } from 'styles';

export const counterInputContainer = (props) => {
  const { numberWidth, numberSize, numberColor } = props;

  return [
    {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '12px 0',

      '.number': {
        color: numberColor ? numberColor : theme.black,
        fontSize: numberSize ? numberSize : 16,
        minWidth: numberWidth ? numberWidth : 30,
        textAlign: 'center',
      },
    },
    ...commonStyles(props),
  ];
};

export const icon = (isDisabled, iconColor) => [
  {
    color: iconColor ? iconColor : theme.gray150,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    opacity: isDisabled ? 0.4 : 1,
  },
  isDisabled && {
    '& *': { pointerEvents: 'none' },
  },
];
