import { getReq, putReq, getAllBookingsParams, bookingDetailsByIdModel } from 'services';

const baseUrl = `${apiUrls.voyageApiUrl}/api/Hotels`;

export const getAllBookings = (options) => getReq(`${baseUrl}/Bookings/List?${getAllBookingsParams(options)}`);

export const getBookingById = async (bookingId) => {
  const [res, err] = await getReq(`${baseUrl}/v2/Bookings/${bookingId}`);
  return [bookingDetailsByIdModel(res), err];
};

export const cancelBookingHotelByIds = (cancelBody) => putReq(`${baseUrl}/Bookings/Cancel`, cancelBody);
