import { Image } from 'components';
import { paymentCartContainer } from './styles';

const PaymentCurrencyCart = ({ amount, title, image, backgroundColor, amountColor, iconHeight, className }) => {
  return (
    <div css={paymentCartContainer(backgroundColor, amountColor, iconHeight)} {...(className && { className })}>
      <div>
        <p className="title">{title}</p>
        <h1 className="amount">{amount}</h1>
      </div>
      <Image src={image} className="icon" />
    </div>
  );
};

export default PaymentCurrencyCart;
