import { useState, useRef } from 'react';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { Button, Input } from 'components';
import { useUserStore } from 'stores';
import { ReactComponent as NoComments } from 'assets/images/no-comments-icon.svg';
import { transactionCommentsContainer } from './styles';

const TransactionComments = ({ comments }) => {
  const user = useUserStore();
  const { getText } = useTranslations();
  const commentInputRef = useRef();
  const [showButtons, setShowButtons] = useState(false);
  const userNames = `${user?.userInfo?.firstName?.substring(0, 1)}${user?.userInfo?.lastName?.substring(0, 1)}`;

  const handleCreateComment = () => {};

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setShowButtons(false);
  };

  const handleCancelComment = () => {
    commentInputRef.current.changeValue('');
    setShowButtons(false);
  };

  return (
    <div css={transactionCommentsContainer}>
      {comments?.length < 1 && (
        <div className="no-comments-container">
          <NoComments className="no-comments-icon" />
          <p>{getText('noCommentsYet')}</p>
        </div>
      )}

      {comments?.length >= 1 &&
        comments?.map((el, ind) => (
          <div className="comments" key={ind}>
            <div className="names">{`${el?.firstName?.substring(0, 1)}${el?.lastName?.substring(0, 1)}`}</div>
            <div className="comment-container">
              <div className="user-date">
                <h5 className="comment-user">{`${el?.firstName} ${el?.lastName}`}</h5>
                <p className="comment-date">{moment(el?.createdDate).format('DD.MM.YYYY')}</p>
              </div>
              <p className="comment">{el?.comment}</p>
            </div>
          </div>
        ))}

      <div onBlur={hideContainer}>
        <Input
          id="comment"
          type="textarea"
          ref={commentInputRef}
          placeholder={getText('leaveComment')}
          className="comment-input"
          leftChild={<div className="name-container">{userNames}</div>}
          onFocus={() => setShowButtons(true)}
        />

        {showButtons && (
          <div className="buttons-container">
            <Button small type="secondary" onClick={handleCancelComment} className="cancel-button">
              {getText('cancel')}
            </Button>
            <Button small onClick={handleCreateComment} className="save-button">
              {getText('post')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionComments;
