import { theme } from 'styles';

export const bookingDetailsContainer = {
  position: 'relative',
  width: '100vw',

  '.container': {
    padding: '0 84px',

    '.section-title': {
      margin: '16px 0',
      fontWeight: 500,
    },

    '.name-location-action-image-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 24,

      '.name-location-image-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.hotel-image': {
          width: 90,
          height: 90,
          borderRadius: 11,
          marginRight: 12,
        },
      },

      '.title': {
        marginBottom: 4,
      },

      '.location': {
        color: theme.gray500,
        marginBottom: 8,
        fontSize: 14,
      },

      '.booking-badge-id-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,

        '.booking-id': {
          fontWeight: 500,
          fontSize: 14,
          color: theme.gray400,
          marginRight: 8,
        },

        '.booking-badge': {
          borderRadius: 20,
          fontWeight: 500,
        },
      },
    },
    '.action-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '.booking-details-button': {
        color: theme.blue,
        fontWeight: 600,
        padding: 0,
        marginRight: 8,

        '&:hover': {
          borderColor: 'none',
        },

        '&:active': { outline: 'none' },
      },

      '.danger-button': {
        backgroundColor: theme.errorLight,
        color: theme.errorDark,
        borderColor: theme.errorLight,
        fontWeight: 600,
      },
    },

    '.horizontal-line': {
      backgroundColor: theme.gray200,
      margin: '24px 0',
    },

    '.booking-details': {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 32,

      '.transaction-details': {
        borderRadius: 10,
        border: `1px solid ${theme.gray150}`,
        boxShadow: theme.boxShadowDark,
        marginBottom: 24,
      },

      '.user-styles': {
        fontSize: 14,
        fontWeight: 600,
        color: theme.gray300,
        textTransform: 'upperCase',
        marginBottom: 8,
      },

      '.user-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        '.user-info-container': {
          display: 'flex',
          flexDirection: 'column',

          '.blue-text': {
            fontSize: 16,
            fontWeight: 500,
            color: theme.blue,
            marginBottom: 4,
          },

          '.gray-text': {
            fontSize: 14,
            color: theme.gray400,
            marginBottom: 4,
          },
        },

        '.user-names': {
          width: 45,
          height: 45,
          backgroundColor: theme.warningLight,
          color: theme.gray800,
          textTransform: 'uppercase',
          fontSize: 18,
          fontWeight: 600,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
  },
};

export const maps = {
  marginBottom: 32,
};
